// @ts-check

/** @typedef {"netbanking" | "upi" | "debit" | "credit" | "wallets" | "cheque"} PaymentGatewayTypes */

/**
 * @typedef {Object} SupportConfiguration
 * @property {string} supportEmail
 * @property {string} billingEmail
 * @property {string[]} contactNumbers
 * @property {string} duration
 * @property {string} brandName
 * @property {string} contactUs
 */

/**
 * @typedef {Object} StoreFrontUrls
 * @property {string} offlinePaymentOptions
 * @property {string} supersiteUrl
 * @property {string} panelInterface
 * @property {string} adamUrl
 * @property {string} whoisUrl
 * @property {string} sampleEmail
 */

/**
 * @typedef {"privacyPolicy" | "legal"} ComplianceUrls
 */

/**
 * @typedef {Object} BrandUrls
 * @property {StoreFrontUrls} storefront
 * @property {Record<ComplianceUrls, string> & {ccpa?: string} } compliance
 */

/**
 * @typedef {Object} PaymentSettings
 * @property {"modern" | "list"} displayMode
 * @property {PaymentGatewayTypes[]} autoRenewDisallowedFor
 * @property {Record<PaymentGatewayTypes, number>} [categories] PG Mapping
 */

/**
 * @typedef {Object} Scenes
 * @property {boolean} [ARTokenNotification]
 * @property {boolean} [CrossSellBanners]
 */

/**
 * @typedef {Object} BrandConfiguration
 * @property {string} brand
 * @property {SupportConfiguration?} support Support configuration for this brand.
 * @property {BrandUrls} urls Brand Specific Urls
 * @property {Scenes} scenes
 * @property {PaymentSettings} paymentSettings PG Mapping
 */

/** @type {Record<string, BrandConfiguration>} */
const BrandConfigurationById = {
  "247133": {
    brand: "bigrock",
    urls: {
      compliance: {
        legal: "https://www.bigrock.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "https://bigrock.in/offline-payment-options",
        supersiteUrl: "https://www.bigrock.in",
        adamUrl: "https://helpdesk.bigrock.in",
        panelInterface: "https://myorders.bigrock.in",
        whoisUrl: "https://bigrock.in/whois-lookup.php",
        sampleEmail: "yourdomain@bigrock.in"
      }
    },
    paymentSettings: {
      displayMode: "modern",
      autoRenewDisallowedFor: ["netbanking"],
      categories: {
        netbanking: 111891,
        upi: 59483,
        debit: 118298,
        credit: 99278,
        wallets: 118299,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: true,
      CrossSellBanners: true
    },
    support: {
      brandName: "BigRock",
      supportEmail: "support@bigrock.com",
      billingEmail: "billing@bigrock.com",
      contactNumbers: ["0824-6614011", "0824-2868080"],
      duration: "(9AM - 8PM)",
      contactUs: "https://www.bigrock.in/support/contact-us"
    }
  },
  "147142": {
    brand: "hostgator",
    urls: {
      compliance: {
        legal: "https://www.hostgator.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions:
          "https://www.hostgator.in/support/payment-options.php",
        adamUrl: "https://helpdesk.hostgator.in",
        panelInterface: "https://my.hostgator.in",
        supersiteUrl: "https://www.hostgator.in",
        whoisUrl:
          "https://hostgator.in/domain-registration/domain-whois-lookup.php",
        sampleEmail: "yourdomain@hostgator.in"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [
        "credit",
        "debit",
        "netbanking",
        "upi",
        "wallets"
      ],
      categories: {
        netbanking: 70521,
        upi: 118275,
        debit: 118283,
        credit: 109381,
        wallets: 118275,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "HostGator-India",
      supportEmail: "support@hostgator.in",
      billingEmail: "billing@hostgator.in",
      contactNumbers: ["0824-6614102", "0824-2863636"],
      duration: "(24/7)",
      contactUs: ""
    }
  },
  "20794": {
    brand: "bigrock",
    urls: {
      compliance: {
        legal: "https://www.bigrock.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "/storefront/offline-payment-options",
        adamUrl: "",
        panelInterface: null,
        supersiteUrl: null,
        whoisUrl: "https://bigrock.in/whois-lookup.php",
        sampleEmail: "yourdomain@bigrock.in"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: {
        netbanking: 650,
        upi: 300421,
        debit: 650,
        credit: 844,
        wallets: 650,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: false
    },
    support: {
      brandName: "BigRock",
      supportEmail: "support@bigrock.com",
      billingEmail: "billing@bigrock.com",
      contactNumbers: ["0824-6614011", "0824-2868080"],
      duration: "(24/7)",
      contactUs: "https://www.bigrock.in/support/contact-us"
    }
  },
  "20602": {
    brand: "hostgator",
    urls: {
      compliance: {
        legal: "https://www.bigrock.in/support/legal.php",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "/storefront/offline-payment-options",
        adamUrl: "",
        panelInterface: null,
        supersiteUrl: null,
        whoisUrl: "https://bigrock.in/whois-lookup.php",
        sampleEmail: "yourdomain@bigrock.in"
      }
    },
    paymentSettings: {
      displayMode: "modern",
      autoRenewDisallowedFor: ["netbanking"],
      categories: {
        netbanking: 650,
        upi: 301902,
        debit: 650,
        credit: 844,
        wallets: 650,
        cheque: -1
      }
    },
    scenes: {
      ARTokenNotification: true,
      CrossSellBanners: true
    },
    support: {
      brandName: "BigRock",
      supportEmail: "support@bigrock.com",
      billingEmail: "billing@bigrock.com",
      contactNumbers: ["0824-6614011", "0824-2868080"],
      duration: "(9AM - 8PM)",
      contactUs: ""
    }
  },
  "330623": {
    brand: "resellerclub",
    urls: {
      compliance: {
        legal: "https://india.resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://rclubindia.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubindia.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.in"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "ResellerClub - India",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  "331653": {
    brand: "resellerclub",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://rclubbr.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubbr.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "ResellerClub Brazil",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  "355948": {
    brand: "resellerclub",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://rclubtr.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubtr.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "ResellerClub Turkey",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  "415316": {
    brand: "resellerclub",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://rclubid.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://rclubid.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "ResellerClub Indonesia",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  "1": {
    brand: "resellerclub",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center",
        ccpa:
          "https://www.newfold.com/privacy-center/addendum-for-california-users"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://resellerclub.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://manage.resellerclub.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "ResellerClub",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  },
  "999999998": {
    brand: "resellerclub",
    urls: {
      compliance: {
        legal: "https://resellerclub.com/legal-agreements",
        privacyPolicy: "https://newfold.com/privacy-center"
      },
      storefront: {
        offlinePaymentOptions: "",
        adamUrl: "",
        panelInterface: "https://demo.webpropanel.com",
        supersiteUrl: null,
        whoisUrl: "https://demo.myorderbox.com/whois.jsp",
        sampleEmail: "yourdomain@resellerclub.com"
      }
    },
    paymentSettings: {
      displayMode: "list",
      autoRenewDisallowedFor: [],
      categories: null
    },
    scenes: {
      ARTokenNotification: false,
      CrossSellBanners: true
    },
    support: {
      brandName: "Demo Registrar",
      supportEmail: "",
      billingEmail: "",
      contactNumbers: [],
      duration: "",
      contactUs: ""
    }
  }
};

export const BrandConfigurationOperations = {
  /**
   *
   * @param {BrandConfiguration} brandConfiguration
   * @param {PaymentGatewayTypes} category
   * @returns {number}
   */
  mapCategoryToId: (brandConfiguration, category) =>
    brandConfiguration.paymentSettings.categories[category],
  /**
   *
   * @param {string} parentId
   * @returns {BrandConfiguration}
   */
  findById: parentId => BrandConfigurationById[parentId]
};
