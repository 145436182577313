import { Injection, Syringe } from "@faizaanceg/syringe";
import { SessionManager } from "common/credentials";
import { SiteDetailsManager } from "common/site-details";
import { parseSearch } from "common/urls";
import { OLCredentialsManager } from "./credentials";

const ApiHost: Injection<"apiUrls"> = {
  name: "apiUrls",
  injectFn: () => ({
    apiHost: "/api/orderbox/",
    searchURL: "/api/elastic/",
    cacheableApiHost: "/api/obox-api/"
  }),
  uses: []
};

const CreateSessionManager: Injection<"sessionManager"> = {
  name: "sessionManager",
  injectFn: () =>
    new SessionManager(async () => {
      let res = await fetch(`/api/webpro/me`, { credentials: "include" });
      if (res.ok) {
        let { userDetails, ...session } = await res.json();
        return {
          token: null,
          details: {
            ...session,
            userId: session.userid,
            logoUrl: session.logoUrl
          }
        };
      }
      return null;
    }),
  uses: []
};

const CreateCredentialsManager: Injection<"credentials"> = {
  name: "credentials",
  // @ts-ignore
  injectFn: ({ sessionManager }) => new OLCredentialsManager(sessionManager),
  uses: [{ name: "sessionManager" }]
};

const CreatePortalContainer: Injection<"portalContainer"> = {
  name: "portalContainer",
  injectFn: () => document.body,
  uses: []
};

const CustomerRoleInApi: Injection<"doesApiUseCustomerRole"> = {
  name: "doesApiUseCustomerRole",
  injectFn: () => false,
  uses: []
};

const CompatLayerSettings: Injection<"compatSettings"> = {
  name: "compatSettings",
  injectFn: () => ({ layer: null, needsCompat: false }),
  uses: []
};

const ExternalAccessManagerFactory: Injection<"externalAccessManager"> = {
  name: "externalAccessManager",
  injectFn: () => ({
    createPaymentUrl(key) {
      const hostname = `https://${
        typeof window != "undefined" ? window.location.hostname : ""
      }/api`;
      return `${hostname}/transactions/status/${key}`;
    },
    createWindow(target) {
      return window.open("/loader.html", target);
    },
    createCurrentHost() {
      return "";
    }
  }),
  uses: []
};

const UserConstraintsFactory: Injection<"userConstraints"> = {
  name: "userConstraints",
  injectFn: () => ({
    addFundsOnDemand: process.env.FEATURE_ENDPOINT === "reseller",
    useLegacyPayment: false,
    manageAutoRenewal: true,
    useConsolidatedPricing:
      process.env.FEATURE_ENDPOINT === "bigrock" &&
      process.env.BUILD_ENV === "production",
    hasRecentSearches: process.env.FEATURE_ENDPOINT === "reseller",
    manageSitesNative: true
  }),
  uses: []
};

const DefaultUrls = {
  customer: "same665954.manage-orders.com",
  bigrock: "myorders.bigrock.in",
  reseller: "demo.webpropanel.com"
};

const SiteUrlProviderFactory: Injection<"siteUrlProvider"> = {
  name: "siteUrlProvider",
  injectFn: () => {
    if (process.env.BUILD_ENV === "production") {
      return () => window.location.hostname;
    }
    return () => {
      const qs = parseSearch(window.location.search);
      return qs.url ?? DefaultUrls[process.env.FEATURE_ENDPOINT];
    };
  },
  uses: []
};

Syringe.fill([
  ApiHost,
  CreateSessionManager,
  CreateCredentialsManager,
  CreatePortalContainer,
  CustomerRoleInApi,
  CompatLayerSettings,
  ExternalAccessManagerFactory,
  UserConstraintsFactory,
  SiteUrlProviderFactory,
  SiteDetailsManager.register()
]);
