import { colors, fonts } from "common/styles/index";
import { css } from "linaria";
import { styled } from "linaria/react";

export let WPModalFlex = styled.div`
  display: flex;
  justify-content: space-between;
  flex: auto;
`;

export let WPModalContent = styled.div`
  flex: auto;
  padding: 25px 30px 30px;
`;

export let WPModalActions = styled.div`
  display: flex;
  align-items: center;
`;

export let ModalContentShift = styled.div`
  margin-left: 12px;
`;

export let ModalText = styled.div`
  font: 400 13px/20px ${fonts.OpenSans};
  color: ${colors.black.regular};
  margin-top: 18px;
`;
export let ModalConfirmText = styled.div`
  font: 600 12px/17px ${fonts.OpenSans};
  color: ${colors.black.regular};
  margin-top: 22px;
  margin-bottom: 16px;
`;

export let WPModalNote = styled.div`
  font: 400 12px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray900};
  margin-top: 10px;
`;

export let WPButton = styled.button`
  border: none;
  background: ${colors.blue.regular};
  color: ${colors.white.regular};
  font: 600 12px ${fonts.OpenSans};
  padding: 12px 20px;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  border-radius: 2px;
  transition: all 0.2s ease-in;
  &:hover {
    background: ${colors.blue.hover};
  }
  &[data-btn-color="red"] {
    background: ${colors.red.regular};
    &:hover {
      background: ${colors.red.hover};
    }
  }
  &[data-btn-color="green"] {
    background: ${colors.green.regular};
    &:hover {
      background: ${colors.green.hover};
    }
  }
`;

export let WPButtonHollow = styled.button`
  color: ${colors.blue.regular};
  border: 1px solid ${colors.blue.regular};
  background: transparent;
  padding: 7px 14px 8px;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  font: 600 11px ${fonts.OpenSans};
  display: inline-block;
  transition: 0.2s;
  line-height: 1;
  &:hover {
    border: 1px solid #5da3ff;
    color: #5da3ff;
  }
`;

export let WPLinkButton = styled.button`
  color: ${colors.blue.regular};
  margin-left: 12px;
  text-decoration: none;
  cursor: pointer;
  font-family: ${fonts.OpenSans};
  border: 0;
  padding: 0;
  outline: 0;
  background: transparent;
  text-transform: uppercase;
`;

export let WPModalTitle = styled.div`
  font: 700 15px ${fonts.BrandonGrotesque};
  color: ${colors.black.regular};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export let WPModalContentBody = styled.div`
  position: relative;
`;

export let WPModalContentRight = styled.div`
  background: ${colors.gray.gray100};
  padding: 25px 30px;
  width: 30%;
  border-radius: 0 2px 2px 0;
  min-width: 198px;
  font-family: ${fonts.OpenSans};
  p {
    color: #697489;
    font-size: 11px;
    margin-bottom: 12px;
    line-height: 18px;
  }
  strong {
    display: inline-block;
    font-weight: 600;
  }
`;

export let DialogOverlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(28, 31, 39, 0.8);
  width: 100%;
  height: 100%;
  animation: 0.2s "fade-in" linear;
  z-index: 2000;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
`;

export let DialogContentStyle = css`
  background: ${colors.white.regular};
  margin: 10vh auto;
  width: 50vw;
  position: relative;
  border-radius: 2px;
  display: flex;
  align-items: flex-start;
`;

export let WPModalClose = styled.div`
  position: absolute;
  color: ${colors.white.regular};
  height: 20px;
  width: 20px;
  top: -16px;
  right: -30px;
  cursor: pointer;
  @media (max-width: 767px) {
    right: 5px;
    top: -25px;
  }
`;

export let SecureModal = css`
  border: 2px ${colors.green.regular} solid;
  border-bottom: 28px ${colors.green.regular} solid;
  position: relative;
  border-radius: 2px;

  &:before {
    content: "This Window is secured with 256 bit encryption";
    position: absolute;
    color: ${colors.white.regular};
    bottom: -22px;
    left: 30px;
    font-family: ${fonts.OpenSans};
    font-size: 11px;
  }

  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.91 1.023a3.978 3.978 0 0 0-3.974 3.975v1.918H1.699a.677.677 0 0 0-.675.674v6.73c0 .371.304.675.675.675h10.43a.677.677 0 0 0 .674-.675V7.596a.677.677 0 0 0-.675-.675h-1.237V5.004a3.988 3.988 0 0 0-3.98-3.98zm-2.625 3.98c0-1.45 1.18-2.625 2.626-2.625a2.632 2.632 0 0 1 2.625 2.626V6.92H4.285V5.004zm7.169 8.643h-9.08V8.265h9.08v5.38z' stroke='%23F5F7FA' stroke-width='.35' fill='%23FFF' fill-rule='nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    width: 12px;
    height: 14px;
    background-size: 12px;
    position: absolute;
    bottom: -21px;
    left: 10px;
  }
`;

export let FullScreenModal = css`
  width: 100vw !important;
  height: 100vh;
  overflow: scroll;
  margin: 0 !important;
  @media (max-width: 640px) {
    width: -webkit-fill-available !important;
    height: -webkit-fill-available;
  }
`;
