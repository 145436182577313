import { Syringe } from "@faizaanceg/syringe";
import { isSuccess } from "common/api-status/utils";
import dataLayerPush from "common/datalayer-push";
import history from "common/history";
import { parseStringAsBoolean } from "common/parse-string";
import { getPlanDetails } from "common/plan-details/services";
import { getMetadataFor } from "common/product-metadata";
import { onReload } from "common/reloadable/ducks";
import { CustomerManagementSdk } from "common/sdk/customer-management";
import { TransactionsSdk } from "common/sdk/transactions";
import commonServices from "common/services";
import { actions as sessionManagement } from "common/session-management/ducks";
import { services as sessionServices } from "common/session-management/services";
import { SmartCartSdk } from "common/smartcart/sdk";
import {
  differenceInDaysFromNow,
  formatDate,
  getFormattedDate,
  getIcon,
  getOnHoldOrder,
  getRemainingDaysFromNow,
  getTimestampFromHyphenSeparatedDate,
  hasMore,
  hasOrderExpired,
  isFetchDetailRequiredForOrder,
  isOrderExpiringIn30Days,
  reduceObject
} from "common/util";
import ProductKeyMapping from "constants/ProductKeyMapping";
import { getProductAddonDetails } from "constants/addon-details";
import {
  OrderStatus,
  StorageStatus,
  getSimpleNameFromProductKey,
  shortcuts
} from "constants/index";
import { platform } from "constants/platform";
import {
  PremiumSupportedProducts,
  calculatePremiumRenewPrice
} from "constants/premium-supported-products";
import { addYears } from "date-fns";
import { get, has, isEmpty, isEqual, partition, sortBy, uniq } from "lodash";
import { mapEffect } from "map-effect";
import { services as sitelockServices } from "notifications/manage-sitelock/services";
import { delay } from "redux-saga";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import format from "string-format";
import { actionTypes as emailAddressActionTypes } from "widget-models/email-address/ducks";
import { actionTypes as gsuiteActionTypes } from "widget-models/gsuite-setup/ducks";
import { actionTypes as manageEmailActionTypes } from "widget-models/manage-email/ducks";
import { getTitanMailStatus } from "widget-models/manage-titan/sagas";
import { actionTypes as privacyProtectionActionTypes } from "widget-models/privacy/ducks";
import { autoRenewalSagas } from "../auto-renewal/sagas";
import UpdateActionTypes from "./constants/UpdateActionTypes";
import { addonActionTypes } from "./constants/index";
import { actionTypes, actions, renewActionTypes } from "./ducks";
import { helpers } from "./helpers";
import { services } from "./services";

const sessionManager = Syringe.inject("sessionManager");

export const ordersSagas = [
  takeEvery(actionTypes.MOVE_ORDER_REQUEST, onMoveOrder),
  takeEvery(isSuccess(actionTypes.MOVE_ORDER_REQUEST), updateOrdersAfterMove),
  takeEvery(
    actionTypes.UPDATE_PRICING_AND_ADDON_DETAILS,
    updatePricingAndAddonDetailsSaga
  ),
  takeEvery(actionTypes.GET_DOMAIN_ORDERS, mapEffect(findOrdersByDomain)),
  takeEvery(
    actionTypes.GET_DOMAIN_ORDERS_ORDER_MANAGEMENT,
    mapEffect(onGetOrderManagementOrders)
  ),
  takeEvery(
    isSuccess(actionTypes.GET_DOMAIN_ORDERS_ORDER_MANAGEMENT),
    onGetOboxOrderDetails
  ),
  takeEvery(
    actionTypes.CREATE_CUSTOMER_AND_MOVE_ORDER_REQUEST,
    onCreateCustomerAndMoveOrder
  ),
  takeEvery(actionTypes.GET_ORDERS_REQUEST, onFetchOrders),
  takeEvery(
    [
      actionTypes.GET_OFFLINE_ORDERS_REQUEST,
      isSuccess(actionTypes.DELETE_CART_ITEM)
    ],
    onOrderRequests
  ),
  takeEvery(actionTypes.DELETE_CART_ITEM, mapEffect(onDeleteOfflineOrderItem)),
  takeEvery(
    [
      privacyProtectionActionTypes.PURCHASE_PRIVACY_PROTECTION,
      gsuiteActionTypes.GSUITE_ADMIN_SETUP_REQUEST,
      emailAddressActionTypes.CREATE_EMAIL_ADDRESS,
      manageEmailActionTypes.PURCHASE_EMAIL_ACCOUNTS
    ].map(isSuccess),
    reloadShortcutDetails
  ),
  takeEvery(UpdateActionTypes.map(isSuccess), reloadPage),
  takeEvery(actionTypes.UPDATE_PLAN_DETAILS, mapEffect(onGetPlanDetails)),
  takeEvery(actionTypes.GET_ORDER_LATEST_ACTIVITY, mapEffect(getAllActivity)),
  takeEvery(
    actionTypes.RESEND_VERIFICATION_MAIL,
    mapEffect(onResendVerificationMail)
  ),
  takeEvery(actionTypes.GET_ONHOLD_ORDERS_REQUEST, mapEffect(getOnholdOrders)),
  ...autoRenewalSagas
];

function getOrderById(orderId) {
  return state => state.orders.ordersByOrderID[orderId] ?? {};
}

const getCostPrice = ({ orderDetails, productCategory }) => {
  const resellercost = +orderDetails.resellercost;
  let multiplier = 0;
  if (productCategory === "domorder") {
    if ("NoOfDays" in orderDetails.executioninfoparams) {
      multiplier = +orderDetails.executioninfoparams.NoOfDays;
    } else {
      multiplier = +orderDetails.executioninfoparams.NoOfYears;
    }
  } else {
    multiplier = +orderDetails.executioninfoparams.noofmonths;
  }
  return resellercost * multiplier;
};

function getCustomerById(customerId) {
  return state => state.orders.customersByID[customerId] ?? null;
}

function getPaginationInfo(orderType) {
  return state => {
    let { maxCount, orderIDs } = state.orders[orderType] ?? {};
    const nonTrialOrderIDsCount = orderIDs.filter(Number).length;
    return {
      maxCount,
      nextCount: nonTrialOrderIDsCount,
      hasMore:
        (maxCount === 0 && nonTrialOrderIDsCount === 0) ||
        hasMore(state.orders[orderType]).hasMore
    };
  };
}

function* onCreateCustomerAndMoveOrder({ values, losingCustomer, domainName }) {
  try {
    const customerid = yield CustomerManagementSdk.create(values);
    dataLayerPush("CustomerCreate", {
      primaryDetails: {
        name: values.name,
        emailId: values.username,
        countryCode: values.phone_cc,
        phoneNo: values.phone
      },
      aboutTheirBusiness: {
        companyName: values.company,
        address: values.address_line_1,
        pin: values.zipcode,
        state: `${values.state} ${values.country}`,
        city: values.city
      }
    });

    yield CustomerManagementSdk.orders.move(
      domainName,
      losingCustomer,
      customerid
    );
    yield put(
      actions.moveOrderSuccess({ customerid, name: values.name }, domainName)
    );
  } catch (error) {
    yield put(actions.moveOrderFailure(error));
  }
}

function* onMoveOrder({ domainName, losingCustomer, gainingCustomer }) {
  try {
    yield CustomerManagementSdk.orders.move(
      domainName,
      losingCustomer,
      gainingCustomer.customerId
    );
    yield put(
      actions.moveOrderSuccess(
        { customerid: gainingCustomer.customerId, name: gainingCustomer.value },
        domainName
      )
    );
  } catch (error) {
    yield put(actions.moveOrderFailure(error));
  }
}

function* updateOrdersAfterMove(action) {
  let { customerid, domainName } = action;
  yield call(getCustomerDetails, { customerid }, { forceReload: true });

  let ordersToUpdate = yield select(state =>
    Object.values(state.orders.ordersByOrderID)
      .filter(_ => _.domainname === domainName)
      .map(_ => _.orderid)
  );
  yield all(
    ordersToUpdate.map(orderId =>
      call(
        mapEffect(getLatestActivity, actionTypes.GET_ORDER_LATEST_ACTIVITY),
        { orderId }
      )
    )
  );
}

async function findOrdersByDomain({ domainName }) {
  const params = {
    "page-no": 1,
    "no-of-records": 50,
    "domain-name": domainName
  };

  let { details } = await sessionManager.create();

  if (details.role !== "reseller") {
    params["customer-id"] = details.userId;
  } else {
    params["list-child-orders"] = true;
  }
  const { orderList = [] } = await services.getOrders(0, params);
  return orderList;
}

function hasExternalPanelShortcutsOnly(productDetailsKey) {
  return (
    productDetailsKey.startsWith("codeguard") ||
    productDetailsKey.startsWith("hosting")
  );
}

function onResendVerificationMail(action) {
  services.resendVerificationMail(action.orderId);
}

function* getShortcuts(productKey = "", orderid, meta, orderDetails) {
  const {
    platform,
    location,
    meta: { endpoints }
  } = ProductKeyMapping[productKey];
  const urlProps = { platform, location, orderid };

  let newActionList = [];

  if (productKey === "domorder") {
    const {
      privacyprotectedallowed = "false",
      privacyprotectendtime,
      raaVerificationStatus = ""
    } = orderDetails;
    newActionList.push(shortcuts.edit_nameservers);
    // privacy protection is allowed but not purchased
    newActionList.push(
      parseStringAsBoolean(privacyprotectedallowed) && !privacyprotectendtime
        ? shortcuts.privacyprotection
        : shortcuts.edit_contact
    );
    if (raaVerificationStatus.toLowerCase() === "pending") {
      newActionList.push(shortcuts.pending_domain);
    }
  } else if (productKey === "enterpriseemailus") {
    const {
      response: {
        domainAccountsInfo: { noOfMailAccounts: usedAccounts }
      }
    } = yield services.get(format(endpoints.getUsedAccounts, urlProps));
    if (orderDetails.emailaccounts > usedAccounts) {
      newActionList.push(shortcuts.email_account);
    }
    newActionList.push(shortcuts.manage_email);
    newActionList.push(shortcuts.buy_email_accounts);
  } else if (productKey === "eeliteus") {
    const {
      response: {
        domainAccountsInfo: { noOfMailAccounts: usedAccounts }
      }
    } = yield services.get(format(endpoints.getUsedAccounts, urlProps));
    if (orderDetails.emailaccounts > usedAccounts) {
      newActionList.push(shortcuts.email_account);
    }
    newActionList.push(shortcuts.manage_email);
    newActionList.push(shortcuts.buy_email_accounts);
  } else if (productKey === "sslcert") {
    const { actionstatus = "" } = orderDetails;
    switch (actionstatus.toUpperCase()) {
      case "WAITING_FOR_ENROLLMENT": {
        newActionList = [shortcuts.issue_certificate];
        break;
      }
      case "WAITING_FOR_VERIFICATION": {
        newActionList = [shortcuts.resendVerificationEmail];
        break;
      }
      default: {
        newActionList = [shortcuts.view_certificate, shortcuts.reissue];
      }
    }
  } else if (productKey.startsWith("gapps")) {
    const { actionstatus = "" } = orderDetails;
    newActionList = [
      {
        ...shortcuts.manage_email,
        isInactive: actionstatus.toUpperCase() === "WAITING_FOR_ADMIN_DETAILS",
        inactiveText: "You need to set up your admin account first"
      },
      actionstatus.toUpperCase() === "WAITING_FOR_ADMIN_DETAILS"
        ? shortcuts.pending_gsuite
        : shortcuts.buy_email_accounts
    ];
  } else if (productKey.startsWith("sitelock")) {
    newActionList = [shortcuts.sitelock_panel];
    try {
      const threatList = yield sitelockServices.getThreats({
        endpoints,
        domainname: meta.domainname,
        customerid: meta.customerid
      });
      if (threatList && threatList.length > 0) {
        newActionList.push(shortcuts.sitelock_threat);
      }
    } catch (error) {}
  } else if (productKey.startsWith("blockstorage")) {
    let blockstoragePlanDetails = yield call(onGetPlanDetails, {
      meta: { productKey, endpoints }
    });
    if (
      blockstoragePlanDetails[orderDetails.planid].maximumStorageSize !==
        orderDetails.storageSizeGb &&
      orderDetails.storageStatus.toLowerCase() === StorageStatus.ATTACHED
    ) {
      newActionList = [
        {
          ...shortcuts.manage_volumes,
          sofiKey: "TOOLTIP_SHORTCUTS_UPGRADE_STORAGE",
          tooltipText: "Upgrade Storage"
        }
      ];
    }
  } else if (productKey === "virtualserverlinuxus") {
    newActionList = [shortcuts.manage_addons, shortcuts.manage_panel];
    if (orderDetails.isSSDPlan) {
      newActionList.push(shortcuts.manage_volumes);
    }
  } else if (productKey.startsWith("titanmail")) {
    let buyEmailShortcut = { ...shortcuts.buy_email_accounts };
    let buyNowShortcut;
    if (orderDetails.is_free) {
      buyEmailShortcut = {
        ...buyEmailShortcut,
        type: "upgradeTitan",
        sofiKey: "TOOLTIP_SHORTCUTS_BUY_MORE_EMAIL",
        tooltipText: "Buy Titan email"
      };
    }
    if (
      orderDetails.is_free &&
      (parseStringAsBoolean(orderDetails.isOrderSuspendedUponExpiry) ||
        (orderDetails.currentstatus.toLowerCase() !== "suspended" &&
          orderDetails.currentstatus.toLowerCase() !== "inactive"))
    ) {
      buyNowShortcut = {
        ...buyEmailShortcut,
        type: "buynow",
        sofiKey: "TOOLTIP_SHORTCUTS_BUY_MORE_EMAIL",
        tooltipText: "Buy Titan email"
      };
    }
    newActionList.push(shortcuts.manage_email);
    newActionList.push(buyEmailShortcut);
    buyNowShortcut && newActionList.push(buyNowShortcut);
    if (orderDetails.is_free) {
      return newActionList;
    }
  }
  if (process.env.FEATURE_ENDPOINT === "customer") {
    const onholdOrders = yield select(state => state.orders.onholdOrders);
    if (productKey === "sslcert") {
      const { endtime } = orderDetails;
      if (endtime) {
        const daysRemaining = differenceInDaysFromNow(endtime);
        newActionList.push({
          ...shortcuts.renew,
          tooltipText:
            daysRemaining > 90
              ? "You can't renew this order yet. Renewal is only possible during the last 90 days"
              : "Renew",
          isInactive: daysRemaining > 90
        });
      }
    } else if (
      (hasOrderExpired(orderDetails.endtime) ||
        (isOrderExpiringIn30Days(orderDetails.endtime) &&
          orderDetails.currentstatus === "Active") ||
        parseStringAsBoolean(orderDetails.isOrderSuspendedUponExpiry)) &&
      !getOnHoldOrder(orderid, onholdOrders) &&
      newActionList.length > 0
    ) {
      // TODO: Fix customer renewal modal for non-expiring orders
      newActionList.push(shortcuts.renew);
    }
  }

  if (process.env.FEATURE_ENDPOINT === "bigrock") {
    if (
      productKey.startsWith("titanmail") &&
      newActionList.length > 0 &&
      (parseStringAsBoolean(orderDetails.isOrderSuspendedUponExpiry) ||
        (orderDetails.currentstatus?.toLowerCase() !== "suspended" &&
          orderDetails.currentstatus.toLowerCase() !== "inactive"))
    ) {
      newActionList.push(shortcuts.renew);
    } else if (newActionList.length > 0 && !productKey.includes("titan")) {
      newActionList.push(shortcuts.renew);
    }
  }

  return newActionList;
}

function* onGetShortcuts(
  productKey,
  productCategory,
  orderID,
  meta,
  oboxDetails
) {
  if (productCategory === "domorder") {
    productKey = productCategory;
  }
  try {
    let actionList = yield getShortcuts(productKey, orderID, meta, oboxDetails);
    yield put(actions.getShortcutsSuccess({ actionList, orderID }));
  } catch (error) {
    yield put(actions.getShortcutsError({ orderID }));
  }
}

function* onFetchOrders(action) {
  let handler;
  switch (action.orderType) {
    case "recent":
    case "autoRenewable":
      handler = onRecentOrders;
      break;
    case "expiring":
    case "expired":
      handler = onGetRenewableOrders;
      break;
    case "renewable":
      handler = onRenewableOrders;
      break;
    case "dashboard":
      handler = onDashboard;
      break;
    default:
      return;
  }
  yield call(
    handler,
    action.orderType,
    action.loadFromStart,
    action.loadShortcuts
  );
}

function* onDeleteOfflineOrderItem(action) {
  yield SmartCartSdk.delete(action.payload.cartID, action.payload.itemID);
}

function* getAutoRenewDetails(orderIds) {
  try {
    let detailsByOrderId = yield services.getAutoRenewDetails(orderIds);
    yield put(actions.getAutoRenewDetailsSuccess(detailsByOrderId));
  } catch (error) {
    yield put(actions.getAutoRenewDetailsFailure(error));
  }
}

function* getRenewCostById(orders) {
  let [domainOrders, hostingOrders] = partition(
    orders,
    _ => _.productCategory === "domorder"
  );
  let hostingCostById = {};
  if (hostingOrders.length > 0) {
    hostingCostById = yield call(
      services.getTotalRenewCost,
      hostingOrders.map(_ => _.orderid)
    );
  }
  let domainProductPricing = [];
  if (domainOrders.length > 0) {
    domainProductPricing = yield all(
      uniq(domainOrders.map(_ => _.productKey)).map(productKey =>
        call(getPricingFromProductKey, productKey)
      )
    );
  }
  let domainPricingByProduct = domainProductPricing
    .map(_ => _.reseller_pricing)
    .reduce(reduceObject, {});
  let domainCostById = {};
  for (const { orderid, productKey } of domainOrders) {
    domainCostById[orderid] = {
      reseller_pricing: {
        [productKey]: domainPricingByProduct[productKey]
      }
    };
  }

  const role = yield select(state => state.root.userInfo.role);
  if (role !== "reseller") {
    for (const orderid in hostingCostById) {
      hostingCostById[orderid] = {
        ...hostingCostById[orderid],
        resellerrenewcost: hostingCostById[orderid].customerrenewcost
      };
    }
  }

  return { ...hostingCostById, ...domainCostById };
}

function* loadRenewCost(orders, renewCostById) {
  yield all(
    orders.map(order =>
      call(function* addRenewCost() {
        let oboxDetails = yield call(
          getOrderDetails,
          helpers.formatOrderList(order),
          { getRawData: true }
        );
        yield call(
          updatePricingAndAddonDetails,
          order,
          oboxDetails,
          renewCostById[order.orderid] ?? null
        );
      })
    )
  );
}

function* loadAuxiliaryInfo(
  formattedOrders = [],
  { loadShortcuts = false, checkStatus = false }
) {
  yield all(
    formattedOrders.map(order =>
      call(function* loadSingleData() {
        if (
          loadShortcuts &&
          !hasExternalPanelShortcutsOnly(order.productDetailsKey) &&
          order?.currentstatus.toLowerCase() !== "dummy"
        ) {
          let oboxDetails = yield call(getOrderDetails, order, {
            getRawData: true
          });
          yield fork(
            onGetShortcuts,
            order.meta.productkey,
            order.productcategory,
            order.orderid,
            order.meta,
            oboxDetails
          );
        }
      })
    )
  );
  if (process.env.FEATURE_AUTO_RENEWAL) {
    let ordersAutorenewalEnabled = formattedOrders
      .filter(order => !order.isExpired && order.isExpiring && order.recurring)
      .map(order => order.orderid);
    if (checkStatus && ordersAutorenewalEnabled.length > 0) {
      yield fork(getAutoRenewDetails, ordersAutorenewalEnabled);
    }
  }
}

async function addPendingTransactionInfo(orders = []) {
  const { recsindb, recsonpage, ...pendingTransactions } =
    await TransactionsSdk.customer.pending(
      orders
        .filter(order => order.currentstatus !== "dummy")
        .map(order => order.orderid)
    );
  const pendingTransactionsList = Object.values(pendingTransactions);
  if (
    pendingTransactionsList.length === 0 ||
    recsindb === "0" ||
    recsonpage === "0"
  ) {
    return orders;
  }
  const details = await TransactionsSdk.customer.details(
    pendingTransactionsList.map(transaction => transaction.transid)
  );
  const transactionsByOrderId = Object.fromEntries(
    Object.values(details).map(txn => [txn.orderid, txn])
  );
  return orders.map(order => {
    const pendingTransaction = transactionsByOrderId[order.orderid];
    return pendingTransaction ? { ...order, pendingTransaction } : order;
  });
}

function* onRecentOrders(_, loadFromStart, loadShortcuts = false) {
  let maxRetrievable = 10;
  let orderType = "recent";
  const isActiveDomainOrder = order =>
    order["product.productcategory"] === "domorder" &&
    order["currentstatus"] === "active";

  try {
    const { nextCount, maxCount, hasMore } = yield select(
      getPaginationInfo(orderType)
    );
    if (hasMore || loadFromStart) {
      let from = loadFromStart ? 0 : nextCount;
      let customerId = yield select(state => state.root.userInfo.userid);
      const params = {
        "page-no": Math.floor(from / maxRetrievable) + 1,
        "no-of-records": maxRetrievable
      };
      const role = yield select(state => state.root.userInfo.role);

      if (!(role === "reseller")) {
        params["customer-id"] = customerId;
      }
      const { orderList = [], totalCount: ordersCount } =
        yield services.getOrders(from, params);
      const domOrders = orderList.filter(isActiveDomainOrder);
      const responses = {};

      yield put(actions.getPlanDetails({ productKey: "titanmailglobal" }));
      yield put(actions.getPlanDetails({ productKey: "titanmailindia" }));
      for (const order of domOrders) {
        const data = yield call(getTitanMailStatus, {
          payload: {
            meta: {
              customerid: order.customerid,
              domainname: order.domainname,
              orderid: order.orderid
            }
          },
          domainOrder: orderList.map(helpers.formatOrderList)
        });

        responses[order.orderid] = data;
      }

      const dummyOrdersById = yield select(
        state => state.orders.ordersByOrderID
      );
      const updatedOrderList = orderList.flatMap((order, index) => {
        if (isActiveDomainOrder(order)) {
          const data = responses[order.orderid];
          const { dummyOrderId, showTitan } = data;
          if (showTitan) {
            const meta = {
              domainname: order.domainname,
              customerid: order.customerid
            };
            const dummyOrder = dummyOrdersById[dummyOrderId];
            const updatedOrder = {
              ...order,
              ...dummyOrder,
              orderid: dummyOrderId + order.domainname,
              meta: {
                ...dummyOrder.meta,
                ...meta,
                orderid: dummyOrderId + order.domainname
              }
            };
            return [order, updatedOrder];
          }
          return order;
        }
        return order;
      });
      let recentOrders = updatedOrderList.map(helpers.formatOrderList);

      if (process.env.FEATURE_ENDPOINT !== "bigrock") {
        let ordersWithTransactionInfo = yield addPendingTransactionInfo(
          updatedOrderList
        );
        recentOrders = ordersWithTransactionInfo.map(helpers.formatOrderList);
      }
      yield fork(loadAuxiliaryInfo, recentOrders, { loadShortcuts });
      if (process.env.FEATURE_HAS_CUSTOMERS) {
        yield fork(loadCustomerInfo, orderList, { loadRenewalInfo: false });
      }
      yield put(
        actions.getOrdersSuccess(
          recentOrders,
          orderType,
          nextCount === 0 ? ordersCount : maxCount
        )
      );
    }
  } catch (error) {
    yield put(actions.getOrdersFailure(orderType, error));
  }
}

function* onOrderRequests() {
  let orderType = "orderRequests";
  let orderRequestsObject = {};
  try {
    const [orderRequests = []] = yield SmartCartSdk.list(0);
    orderRequestsObject.data = orderRequests;
    let orderRequestProductCategoryList;
    let orderRequestCustomerIDList = new Set();

    orderRequests?.forEach(request => {
      if (request.customer_id !== "") {
        orderRequestCustomerIDList.add(request.customer_id);
        orderRequestProductCategoryList = new Set(
          request.items.map(item => {
            const selectedProductCategory =
              item.product_key === "domain"
                ? item.extra_details.class_key
                : item.product_key;
            return selectedProductCategory;
          })
        );
      }
    });
    yield put(actions.getPlanDetails({ productKey: "enterpriseemailus" }));
    yield put(actions.getPlanDetails({ productKey: "eeliteus" }));

    let orderRequestCustomers = [];
    for (let customerid of orderRequestCustomerIDList) {
      let customer = yield call(getCustomerDetails, { customerid });
      orderRequestCustomers.push({
        customer_id: customer.customerid,
        name: customer.name,
        company_name: customer.company
      });
    }
    orderRequestsObject.customer_details = orderRequestCustomers;

    yield put(
      actions.getOrderRequestsSuccess(orderRequestsObject, orderType, 500)
    );
  } catch (error) {
    yield put(actions.getOrderRequestsFailure(orderType, error));
  }
}

function* getPricingDetails(
  order,
  orderDetails,
  addonsPricing = {},
  renewCost
) {
  try {
    // TODO: Remove order in favor of orderDetails
    const {
      orderid,
      product_key,
      productKey = product_key,
      "product.productcategory": productCategory = "",
      productcategory = productCategory,
      domainname,
      endtime,
      expiryTimestamp = endtime,
      currentstatus
    } = order;
    const role = yield select(state => state.root.userInfo.role);
    let pricingDetails;
    if (renewCost === null) {
      if (productcategory === "domorder") {
        pricingDetails = addonsPricing;
      } else {
        let hostingPricingById = yield services.getTotalRenewCost(orderid);
        const role = yield select(state => state.root.userInfo.role);
        if (role !== "reseller") {
          for (const orderid in hostingPricingById) {
            hostingPricingById[orderid] = {
              ...hostingPricingById[orderid],
              resellerrenewcost: hostingPricingById[orderid].customerrenewcost
            };
          }
        }
        pricingDetails = hostingPricingById[orderid];
      }
    } else {
      pricingDetails = renewCost;
    }
    if (productcategory === "domorder") {
      const { rgpdate, deletiondate } = orderDetails;
      let domainPricing = (pricingDetails.reseller_pricing ?? pricingDetails)?.[
        productKey
      ];
      let customerDomainPricing = (pricingDetails?.customer_pricing ??
        addonsPricing?.customer_pricing)?.[productKey];
      if (PremiumSupportedProducts.includes(productKey)) {
        let premiumCheckResult = yield commonServices.getPremiumDomainPrice(
          domainname
        );

        let renewPrice = calculatePremiumRenewPrice(premiumCheckResult, role);
        if (renewPrice !== null) {
          domainPricing.renewdomain = { 1: renewPrice };
          customerDomainPricing.renewdomain = { 1: renewPrice };
        }
      }
      if (currentstatus.toLowerCase() === OrderStatus.PENDING_DELETE) {
        return {
          pricingDetails:
            domainPricing.restoredomain ?? domainPricing.renewdomain,
          customerPricing:
            customerDomainPricing.restoredomain ??
            customerDomainPricing.renewdomain ??
            {},
          domainname,
          hideTenureDropdown: true,
          priceSubText: "Restoration Cost",
          tenureSubText: formatDate(addYears(new Date(), 1)),
          tenure: "1 year",
          isInRestorationPhase: true,
          remainingDaysToRestore: getRemainingDaysFromNow(
            getTimestampFromHyphenSeparatedDate(rgpdate)
          ),
          deletesOn: getFormattedDate(
            getTimestampFromHyphenSeparatedDate(deletiondate)
          )
        };
      } else {
        /** @type {ReturnType<typeof getMetadataFor>} */
        let metadata = yield select(
          getMetadataFor(productKey, productcategory)
        );
        return {
          pricingDetails: helpers.addTenuresForDomainRenewalPrices(
            domainPricing.renewdomain,
            expiryTimestamp,
            role,
            metadata?.actions?.renewdomain
          ),
          customerPricing: customerDomainPricing
            ? helpers.addTenuresForDomainRenewalPrices(
                customerDomainPricing?.renewdomain,
                expiryTimestamp,
                role,
                metadata?.actions?.renewdomain
              )
            : {}
        };
      }
    }
    if (productKey === "hosting" && role === "reseller") {
      /* This is a workaround for websitebiulder where the reseller renewcost is fixed for a month
          so the following snippet takes the customer tenures and equivalently multiplies the per month cost by that much
      */
      const { resellerrenewcost, customerrenewcost } = pricingDetails;
      const resellerRenewCost = Object.fromEntries(
        Object.keys(pricingDetails.customerrenewcost).map(tenure => [
          tenure,
          resellerrenewcost["1"]
        ])
      );
      return {
        pricingDetails: resellerRenewCost,
        customerPricing: customerrenewcost
      };
    }
    if (typeof pricingDetails.resellerrenewcost === "object") {
      return {
        pricingDetails: pricingDetails.resellerrenewcost,
        customerPricing: pricingDetails.customerrenewcost
      };
    }
  } catch (error) {
    // Important error, should be tracked
    console.error(error);
    return {};
  }
}

function* loadCustomerInfo(orders, { loadRenewalInfo = false }) {
  let uniqueCustomers = uniq(orders.map(order => Number(order.customerid)));
  let customerDetails = yield all(
    uniqueCustomers.map(customerid => call(getCustomerDetails, { customerid }))
  );
  let customerDetailsToStore = [];
  customerDetails.forEach(customer =>
    orders
      .filter(order => order.customerid === +customer.customerid)
      .forEach(order =>
        customerDetailsToStore.push({
          orderid: order.orderid,
          customer
        })
      )
  );
  yield all(
    customerDetailsToStore.map(details =>
      put(actions.populateCustomerDetails(details))
    )
  );
  if (loadRenewalInfo) {
    yield all(
      customerDetailsToStore.map(details =>
        put(
          actions.updateRenewalDetails({
            type: "customer",
            orderid: details.orderid,
            value: details.customer
          })
        )
      )
    );
  }
}

export function* getOrderDetails(
  order,
  options = { forceReload: false, getRawData: false }
) {
  let { orderid } = order;
  let obData = yield select(getOrderById(orderid));
  if (
    obData.orderid &&
    has(obData, ["oboxOrderDetails", "orderid"]) &&
    !options.forceReload
  ) {
    return options.getRawData ? obData.oboxOrderDetails : obData;
  }
  let keyToGetProductDetails =
    order.productDetailsKey || obData.productDetailsKey;

  let { meta } = get(ProductKeyMapping, [keyToGetProductDetails], {});
  if (meta) {
    const url = format(meta.endpoints.getDetails, { orderid, ...meta });
    try {
      let response = yield call(services.get, url);
      if (getSimpleNameFromProductKey(keyToGetProductDetails) === "sdh") {
        let params = { "order-id": orderid, "plan-id": response.planid };
        let { customerId, role } = yield select(state => {
          return {
            customerId: state.root.userInfo.userid,
            role: state.root.userInfo.role
          };
        });
        if (!(role === "reseller")) {
          params["customer-id"] = customerId;
        }
        const { bandid } = yield commonServices.getBandID(params);
        response = { bandid, ...response };
      }
      yield put(
        actions.populateOboxOrderDetails(
          response,
          url,
          helpers.formatOboxOrderDetails(
            response,
            obData.orderid ? obData : order
          )
        )
      );
      return response;
    } catch (error) {
      console.error(error);
      yield put(actions.populateOboxOrderDetailsFailure(error));
      return order;
    }
  } else {
    console.error(new Error("Failed to update order"));
    return order;
  }
}

export function* getCustomerDetails(
  { customerid },
  options = { forceReload: false }
) {
  let customerData = yield select(getCustomerById(customerid));
  if (!options.forceReload && customerData) {
    return customerData;
  }
  try {
    let response = yield commonServices.getCustomerDetails(customerid);
    yield put(actions.populateCustomerDetails(response));
    return response;
  } catch (error) {
    yield put(actions.populateCustomerDetailsFailure(error));
    return { customerid };
  }
}

function* updatePricingAndAddonDetailsSaga(payload) {
  let pricingDetails = yield call(
    updatePricingAndAddonDetails,
    payload.order,
    null,
    null
  );
  payload.callback(pricingDetails);
}

export function* updatePricingAndAddonDetails(
  order = {},
  oboxDetails,
  renewCost
) {
  try {
    let {
      orderid,
      product_key,
      productKey = product_key,
      "product.productcategory": productCategory,
      productcategory = productCategory,
      product_category,
      endtime: expirydatetime,
      domainname,
      expiryTimestamp: expirytimestamp,
      oboxOrderDetails: orderDetails = oboxDetails,
      customerid
    } = order;
    if (!orderDetails) {
      orderDetails = yield getOrderDetails({ orderid }, { getRawData: true });
    }
    if (productKey.startsWith("singledomain") && !orderDetails.bandid) {
      let params = {
        "order-id": orderDetails.orderid,
        "plan-id": orderDetails.planid
      };
      let { customerId, role } = yield select(state => {
        return {
          customerId: state.root.userInfo.userid,
          role: state.root.userInfo.role
        };
      });
      if (!(role === "reseller")) {
        params["customer-id"] = customerId;
      }
      const { bandid } = yield commonServices.getBandID(params);
      orderDetails = { bandid, ...orderDetails };
    }
    const addonPricingDetails = yield getPricingFromProductKey(
      productKey,
      false,
      customerid
    );

    const { pricingDetails, customerPricing } = yield getPricingDetails(
      order,
      orderDetails,
      addonPricingDetails,
      renewCost
    );
    const keyToGetProductDetails =
      productcategory === "domorder" ? "domorder" : productKey;
    let planDetails = {};
    let miscDetails = {};
    const { meta: productMeta = {} } =
      ProductKeyMapping[keyToGetProductDetails] || {};
    if (getSimpleNameFromProductKey(productKey) === "gsuite") {
      let gsuitePlanDetails = yield call(onGetPlanDetails, {
        meta: { productKey, ...productMeta }
      });
      planDetails = gsuitePlanDetails[orderDetails.planid];
      let {
        isAlreadyRenewedForMoreThan1Year,
        isTransferIn,
        tenureOfOrderInMonths
      } = orderDetails;

      miscDetails = {
        isDiscountApplicable:
          !isAlreadyRenewedForMoreThan1Year &&
          !isTransferIn &&
          tenureOfOrderInMonths < 12
      };
    }
    if (
      getSimpleNameFromProductKey(productKey) === "rchosting" &&
      order.platform === platform.LINUX
    ) {
      let rchostingPlanDetails = yield call(onGetPlanDetails, {
        meta: { productKey, ...productMeta }
      });
      planDetails = rchostingPlanDetails[orderDetails.planid];
      let blocksUsage = yield call(
        services.post,
        format(order.endpoints.getBlocksUsage, order)
      );
      let pricing = yield call(getPricingFromProductKey, productKey);
      miscDetails = {
        addonsPricing: (pricing.reseller_pricing ?? pricing)?.[productKey]?.[
          orderDetails.planid
        ]?.cpanel_blocks,
        accountsUsage: +blocksUsage.resourceUsage.cpanel_accounts
      };
    }
    const addonDetails = getProductAddonDetails(
      { ...orderDetails, miscDetails, planDetails, keyToGetProductDetails },
      pricingDetails,
      addonPricingDetails
    );

    yield put(
      actions.updateRenewalDetails({
        type: "orderdetails",
        orderid,
        addonDetails,
        customerPricing,
        pricingDetails,
        unit: productcategory === "domorder" ? "year(s)" : "month(s)",
        expirydatetime,
        expirytimestamp: expirytimestamp || orderDetails.endtime,
        miscDetails,
        planDetails,
        orderDetails: {
          domainname,
          keyToGetProductDetails,
          productcategory,
          product_category,
          icon: getIcon(productKey),
          productkey: productKey,
          params: helpers.getParams(orderDetails, keyToGetProductDetails)
        }
      })
    );

    return pricingDetails;
  } catch (error) {
    console.error(error);
  }
}

export function* getPricingFromProductKey(
  productKey,
  useFallback = false,
  customerId
) {
  let pricingDetails = { reseller_pricing: {}, customer_pricing: {} };
  let productKeyPricing = yield select(({ root }) =>
    root.consolidatedPricing[productKey]
      ? {
          [productKey]: root.consolidatedPricing[productKey],
          privacy_protection: root.consolidatedPricing.privacy_protection,
          premium_dns: root.consolidatedPricing.premium_dns
        }
      : null
  );

  let productKeyCustomerPricing = yield select(({ root }) =>
    root.perCustomerPricing?.[customerId]?.[productKey]
      ? {
          [productKey]: root.perCustomerPricing?.[customerId]?.[productKey],
          privacy_protection:
            root.perCustomerPricing?.[customerId].privacy_protection,
          premium_dns: root.perCustomerPricing?.[customerId].premium_dns
        }
      : null
  );

  pricingDetails.reseller_pricing = productKeyPricing;

  pricingDetails.customer_pricing =
    process.env.FEATURE_ENDPOINT === "bigrock"
      ? productKeyPricing
      : productKeyCustomerPricing;

  const role = yield select(state => state.root.userInfo.role);
  if (role === "reseller" && (!productKeyPricing || useFallback)) {
    pricingDetails.reseller_pricing =
      yield commonServices.getPricingForProductKey(productKey);
    yield put(
      sessionManagement.storeFallbackPrice(pricingDetails.reseller_pricing)
    );
  }

  const userConstraints = Syringe.inject("userConstraints");
  if (
    !userConstraints.useConsolidatedPricing &&
    !productKeyCustomerPricing &&
    customerId
  ) {
    // Fallback level 2
    let customerPricing = yield sessionServices.getPerCustomerPricing(
      customerId,
      productKey
    );
    pricingDetails.customer_pricing = customerPricing;
    const role = yield select(state => state.root.userInfo.role);
    if (role !== "reseller") {
      pricingDetails.reseller_pricing = customerPricing;
      yield put(
        sessionManagement.storeFallbackPrice(pricingDetails.reseller_pricing)
      );
    }
    yield put(
      sessionManagement.getPerCustomerPricingSuccess({
        customerId,
        customerPricing
      })
    );
  }

  return pricingDetails;
}

function* onRenewableOrders(_, loadFromStart) {
  yield fork(onGetRenewableOrders, "expiring", loadFromStart);
  yield fork(onGetRenewableOrders, "expired", loadFromStart);
}

function* onDashboard() {
  yield fork(onRecentOrders, "recent", true, true);
  yield fork(onRenewableOrders, "renewable", true);
}

function* onGetRenewableOrders(orderType, loadFromStart) {
  try {
    let maxRetrievable = 10;
    const { nextCount, hasMore, maxCount } = yield select(
      getPaginationInfo(orderType)
    );
    if (hasMore || loadFromStart) {
      let isUserOnDashboard = history.location.pathname.includes("dashboard");
      let from = loadFromStart ? 0 : nextCount;

      let params = {
        "order-by": "order_map.endtime"
      };
      let { customerId, role } = yield select(state => {
        return {
          customerId: state.root.userInfo.userid,
          role: state.root.userInfo.role
        };
      });
      if (!(role === "reseller")) {
        params["customer-id"] = customerId;
      } else {
        maxRetrievable = isUserOnDashboard ? 10 : 100;
      }
      const today = Math.floor(Date.now() / 1000);
      if (orderType === "expiring") {
        params = {
          ...params,
          "endtime-end": today + 30 * 24 * 60 * 60,
          "endtime-start": today,
          "order-by": "order_map.endtime"
        };
      } else {
        params = { ...params, "endtime-end": today };
      }
      params = {
        ...params,
        "page-no": Math.floor(from / maxRetrievable) + 1,
        "no-of-records": maxRetrievable
      };
      const { orderList = [], totalCount: ordersCount } =
        yield services.getOrders(from, params);
      let formattedOrders = orderList.map(helpers.formatOrderList);
      if (process.env.FEATURE_ENDPOINT !== "bigrock" && orderList.length > 0) {
        let ordersWithTransactionInfo = yield addPendingTransactionInfo(
          orderList
        );
        formattedOrders = ordersWithTransactionInfo.map(
          helpers.formatOrderList
        );
      }
      yield all(
        formattedOrders.filter(isFetchDetailRequiredForOrder).map(order =>
          call(getOrderDetails, order, {
            getRawData: true
          })
        )
      );
      yield put(
        actions.getOrdersSuccess(
          formattedOrders,
          orderType,
          nextCount === 0 ? ordersCount : maxCount
        )
      );
      let renewCostById = yield call(getRenewCostById, formattedOrders);
      yield call(loadRenewCost, orderList, renewCostById);
      yield call(loadAuxiliaryInfo, formattedOrders, {
        loadShortcuts: role !== "reseller",
        checkStatus: true
      });

      if (process.env.FEATURE_HAS_CUSTOMERS) {
        yield fork(loadCustomerInfo, orderList, { loadRenewalInfo: true });
      }
    }
  } catch (error) {
    yield put(actions.getOrdersFailure(orderType, error));
  }
}

function getOrderIdFromAction(action) {
  let orderid = get(action, "orderid", null);
  orderid = get(action, "orderId", orderid);
  orderid = get(action, "value.entityid", orderid);
  orderid = get(action, "meta.orderid", orderid);
  orderid = get(action, "payload.value.pathParams.orderid", orderid);
  orderid = get(action, "payload.orderid", orderid);
  orderid = get(action, "payload.orderId", orderid);
  return orderid;
}

function* reloadShortcutDetails(action) {
  if (!window.location.pathname.includes("dashboard")) {
    return;
  }
  let orderid = getOrderIdFromAction(action);
  if (!orderid) {
    console.error("Failed to reload shortcuts", action);
    return;
  }
  try {
    let order = yield select(getOrderById(orderid));
    const {
      meta: { productKey, productCategory, ...meta }
    } = order;
    yield delay(1000);
    yield call(
      mapEffect(getLatestActivity, actionTypes.GET_ORDER_LATEST_ACTIVITY),
      { orderId: orderid }
    );
    let oboxDetails = yield call(getOrderDetails, order);
    yield fork(
      onGetShortcuts,
      productKey,
      productCategory,
      orderid,
      meta,
      oboxDetails
    );
  } catch (error) {
    console.error("Error while updating shortcut details for ", orderid);
    console.error(error);
  }
}

function* onGetOrderManagementOrders(action) {
  const orderList = yield call(findOrdersByDomain, action);
  let orders = orderList.filter(_ => _.domainname === action.domainName);
  if (process.env.FEATURE_ENDPOINT !== "bigrock" && orders.length > 0) {
    orders = yield addPendingTransactionInfo(orders);
  }
  const serializedOrders = orders.map(helpers.formatOrderList);
  yield fork(getRestoreDomainDetails, serializedOrders);
  return serializedOrders;
}

function* getRestoreDomainDetails(orders) {
  try {
    let domainOrder = orders.find(
      order => order.productcategory === "domorder"
    );
    if (isEmpty(domainOrder)) {
      return;
    }
    let orderDetails = yield call(getOrderDetails, domainOrder, {
      forceReload: false,
      getRawData: true
    });
    const {
      meta: {
        orderid,
        productcategory,
        domainname,
        product_category,
        expirydatetime,
        expirytimestamp = orderDetails.endtime,
        productKey,
        customerid
      }
    } = domainOrder;
    let keyToGetProductDetails = productcategory;
    let addonsPricing = yield getPricingFromProductKey(
      productKey,
      false,
      customerid
    );

    const pricingDetails = yield getPricingDetails(
      domainOrder,
      orderDetails,
      addonsPricing,
      null
    );

    yield put(
      actions.updateRenewalDetails({
        type: "price",
        orderid,
        value: pricingDetails,
        unit: "year(s)",
        expirydatetime,
        expirytimestamp,
        orderDetails: {
          domainname,
          keyToGetProductDetails,
          productcategory,
          product_category,
          icon: getIcon("domain"),
          productkey: productKey,
          params: helpers.getParams(orderDetails, keyToGetProductDetails)
        }
      })
    );
  } catch (error) {
    console.error(error);
  }
}

function* onGetOboxOrderDetails({ effectResponse: orders }) {
  yield all(orders.map(order => call(getOrderDetails, order)));
  for (const order of orders) {
    yield put(actions.getOrderLatestActivity(order.orderid));
  }
}

function* reloadPage(action) {
  let skipReload = get(action, "skipReload", false);
  if (skipReload) {
    return;
  }
  let orderid = getOrderIdFromAction(action);
  if (!orderid) {
    console.warn("Failed to reload page", action.type);
    return;
  }
  yield delay(1000);
  yield call(
    mapEffect(getLatestActivity, actionTypes.GET_ORDER_LATEST_ACTIVITY),
    { orderId: orderid }
  );
  yield put({ ...action, type: onReload(action.type) });
  if (action.type === isSuccess(renewActionTypes.RENEWAL_REQUEST)) {
    yield put(actions.markAsRenewed(orderid));
  }
}
/**
 *
 * @param {{ meta: { productKey: string } }} action
 * @returns {Record<string, any>}
 */
export function* onGetPlanDetails(action) {
  const planDetails = yield select(state => state.orders.planDetails);
  let planDetailsByProductKey = planDetails?.[action.meta?.productKey];
  if (isEmpty(planDetailsByProductKey)) {
    planDetailsByProductKey = yield call(
      getPlanDetails,
      action.meta?.productKey
    );
    planDetailsByProductKey = planDetailsByProductKey[action.meta?.productKey];
  }
  return planDetailsByProductKey;
}

function* getAllActivity({ orderId }) {
  let archived = {};
  let params = {
    "order-id": orderId,
    "page-no": 1,
    "no-of-records": 10,
    "order-by": "actionadded desc"
  };
  let current = yield services.getCurrentActions(params);
  if (+current.recsindb <= 1) {
    archived = yield services.getArchivedActions(params);
  }
  const currentActions = helpers.serializeActivityResponse(current, false);
  const archivedActions = helpers.serializeActivityResponse(archived, true);
  let activity = [...currentActions, ...archivedActions];
  return { activity };
}
function* getLatestActivity({ orderId }) {
  let existingActivity = yield select(state =>
    sortBy(
      get(state.orders.ordersByOrderID, [orderId, "activity"], []).filter(
        _ => !_.isArchived
      ),
      ["eaqid"]
    )
  );
  let params = {
    "order-id": orderId,
    "page-no": 1,
    "no-of-records": 10,
    "order-by": "actionadded desc"
  };
  let current = yield services.getCurrentActions(params);
  let activity = helpers.serializeActivityResponse(current, false);
  let reloadOrder = !isEqual(existingActivity, sortBy(activity, ["eaqid"]));
  return { activity, reloadOrder };
}

getLatestActivity.onSuccess = function* ({ orderId }, { reloadOrder }) {
  if (reloadOrder) {
    yield call(getOrderDetails, { orderid: orderId }, { forceReload: true });
  }
};

function* getOnholdOrders(action) {
  let onholdOrdersWithDetails = [];
  onholdOrdersWithDetails = yield services.getOnholdOrdersDetails();

  if (onholdOrdersWithDetails.length > 0) {
    const orderIds = onholdOrdersWithDetails.map(order => order.orderId);
    let esDetails = yield services.searchByOrderId(orderIds);
    for (const [index, orderDetail] of onholdOrdersWithDetails.entries()) {
      for (const esDetail of esDetails) {
        if (esDetail.orderId == orderDetail.orderId) {
          onholdOrdersWithDetails[index] = {
            ...orderDetail,
            ...esDetail
          };
        }
      }
    }

    onholdOrdersWithDetails = onholdOrdersWithDetails.map(orderDetails => {
      let { actionType, productCategory, productKey, orderCost, productName } =
        orderDetails;

      const hasRenewalAction = actionType.toLowerCase().includes("renew");
      const hasAddonAction = addonActionTypes.includes(actionType);
      const actionDescription = `${
        productCategory === "domorder" ? "Domain Registration" : productName
      } ${hasRenewalAction ? " (Renewal)" : ""}`;

      return {
        icon: getIcon(productKey),
        isSelected: action.selectedOrderIds
          ? action.selectedOrderIds.includes(orderDetails.orderId)
          : true,
        description: actionDescription,
        actionCostPrice: orderCost,
        hasRenewalAction,
        hasAddonAction,
        ...orderDetails
      };
    });
  }

  return onholdOrdersWithDetails;
}
