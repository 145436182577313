import { Syringe } from "@faizaanceg/syringe";
import { SearchQueryBuilder } from "@superpanel/bodybuilder";
import api from "api";
import { CustomerManagementSdk } from "common/sdk/customer-management";
import { UserDetailsSdk } from "common/sdk/user-details";
import constants from "constants/endpoints";

const SiteManager = Syringe.inject("siteManager");

export const services = {
  generateCustomerSMSOTP: payload =>
    api.postBody(`${constants.webpro}generate-customer-sms-otp`, payload),
  getBrandingDetails: url =>
    api.get(`${constants.webpro}customer/resellerdetails`, { url }),
  /**
   * @param {string} customerId
   * @param {string} productKey
   * @returns {Promise<Record<string, any>>}
   */
  async getPerCustomerPricing(customerId, productKey) {
    return api.get(
      `${constants.bclCacheableOrderBox}products/customer-price.json`,
      { "customer-id": customerId, "product-key": productKey }
    );
  },
  /**
   * @returns {Promise<import("common/orderbox-types").PromoDetails>}
   */
  promoDetails: () =>
    api.get(`${constants.zuulCacheableOrderBox}resellers/promo-details.json`),
  /**
   * @param {string} customerId
   * @returns {Promise<Record<string, any>>}
   */
  async getBulkCustomerPricing(customerId) {
    return api.get(
      `${constants.zuulCacheableOrderBox}products/v2/customer-price.json`,
      { "customer-id": customerId }
    );
  },
  /**
   *
   * @returns {Promise<{ domorder: { domains: Record<string, any>; }; hosting: Record<string, Record<string, any>>; }>}
   */
  async getProductMetadata() {
    return api.get(`${constants.bclOrderBoxV2}products`);
  },
  /**
   *
   * @returns {Promise<{ hosting: string[]; domorder: string[]; }>}
   */
  async getActiveProductsList() {
    return api
      .get(
        `${constants.bclCacheableOrderBox}products/active-product-category-keys-mapping.json`,
        { "include-add-denied-products": false }
      )
      .then(response => ({
        hosting: response.hosting,
        domorder: Object.values(response.domorder ?? {})
      }));
  },
  getDefaultCustomer: () =>
    api
      .get(`${constants.zuulOrderBox}resellers/default-customer`)
      .then(customerId => ({ customerId }))
      .catch(_ => ({ notFound: true })),
  performCustomerLogin: async credentials => {
    credentials.username = credentials.username.toLowerCase();
    return api.postBody(`${constants.webpro}customer/login`, credentials);
  },
  async getSession() {
    let sessionManager = Syringe.inject("sessionManager");
    let session = await sessionManager.create();
    if (session === null) {
      throw new Error("Failed to get session");
    }
    /** @type {boolean} */
    let doesApiUseCustomerRole = Syringe.inject("doesApiUseCustomerRole");
    let { details } = session;
    let userDetails =
      details.role.toLowerCase() === "reseller"
        ? await UserDetailsSdk.reseller()
        : doesApiUseCustomerRole
        ? await UserDetailsSdk.customer()
        : await CustomerManagementSdk.detailsById(details.userId);
    return {
      ...details,
      userid: details.userId,
      role: details.role.toLowerCase(),
      userDetails
    };
  },
  logout: () => api.call("DELETE", constants.logout),
  completeProductSignup: products =>
    api.postBody(
      `${constants.zuulOrderBox}resellers/products/sign-up`,
      products
    ),
  hasGSuite: userId =>
    api
      .post(
        constants.es_search,
        { size: 1 },
        SearchQueryBuilder()
          .filterReseller(userId)
          .filterOrder()
          .filter("terms", "entityTypeId", [524, 679, 525])
          .build()
      )
      .then(response => response.hits?.total?.value > 0),
  /**
   *
   * @param {string} country
   * @returns {Promise<boolean>}
   */
  async isEligibleForGDPR(country) {
    /** @type {string[]} */
    let list = await api.get(
      `${constants.zuulCacheableOrderBox}country/gdpr/list.json`
    );
    if (list.includes(country)) {
      return true;
    }
    let client = await SiteManager.clientDetails();
    return list.includes(client.country);
  },
  /**
   * Logs out user from the SuperSite instance if they're logged in
   * @param {string} url
   */
  async logoutFromSuperSite(url) {
    await fetch(`${url}/login.php?action=signout`, {
      mode: "no-cors",
      credentials: "include"
    }).catch(() => {});
  },
  /**
   * Logs out user from the Adam instance if they're logged in
   * @param {string} url
   */
  async logoutFromAdam(url) {
    await fetch(`${url}/logout`, {
      mode: "no-cors",
      credentials: "include",
      headers: {
        Accept: "application/json"
      }
    }).catch(() => {});
  }
};
