import storage from "@faizaanceg/pandora";
import { Syringe } from "@faizaanceg/syringe";
import classnames from "classnames";
import APIStatus from "common/api-status";
import { isSuccess } from "common/api-status/utils";
import { BrandConfigurationOperations } from "common/brand-configuration";
import UpdateButton from "common/buttons/update";
import datalayerPush from "common/datalayer-push";
import { roundOff } from "common/math";
import ModalHandler from "common/modal-handler";
import TaxPayerID from "common/payment-extra-info/component";
import { WPBorderedRadioButton } from "common/radio-button/bordered-radio";
import { colors, fonts } from "common/styles";
import { formatAmountWithCurrency } from "common/util";
import { gatewayRequiresTaxPayerId } from "constants/index";
import { Form, Formik } from "formik";
import { css, cx } from "linaria";
import { styled } from "linaria/react";
import { getGatewayLogoMultiple } from "modals/add-funds/constants";
import "modals/modals.scss";
import React from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  actionTypes as PayButtonsActionTypes,
  actions as payButtonsActions
} from "../ducks";
import "../styles.scss";
import { PaymentGateways } from "./pg-mapping";

let TaxPayerLegend = styled.div`
  color: ${colors.gray.gray900};
  margin-top: 4px;
  font: 400 12px/1.5 ${fonts.OpenSans};
  margin-left: 22px;
`;

let TitleWrapper = styled.div`
  margin-left: 30px;
  padding-top: 25px;
`;

let RadioDescription = styled.div`
  color: ${colors.gray.gray700};
  margin-left: 26px;
  padding-right: 20px;
`;

const BackButton = styled.button`
  font-size: 13px;
  font-weight: 600;
  margin-left: 11px;
  padding: 0;
`;

const walletId = 1;
class PaymentModal extends React.Component {
  static mapStateToProps = state => ({
    ...state.payButtons,
    ...state.wallet,
    brandConfiguration: BrandConfigurationOperations.findById(
      state.root.userInfo.parentid
    ),
    role: state.root.userInfo.role,
    userCountry: state.root.userInfo.country
  });

  static mapDispatchToProps = {
    getGreedyTransactions: payButtonsActions.getGreedyTransactions,
    payOnline: payButtonsActions.payOnline,
    payWithWallet: payButtonsActions.payWithWallet
  };

  state = {
    paymenttypeid: 0,
    taxPayerIdValue: null,
    canSave: true,
    showPayment: 4
  };

  componentDidMount() {
    if (this.props.role === "reseller") {
      this.props.getGreedyTransactions();
    } else {
      const userConstraints = Syringe.inject("userConstraints");
      if (userConstraints.useLegacyPayment) {
        // -1 is reserved for offline payment
        // -2 is reserved for legacy payment
        this.choosePaymentGateway(-2);
      }
    }
  }

  choosePaymentGateway = (paymenttypeid, gatewaytype) => {
    this.setState({
      paymenttypeid
    });
    const cs = !gatewayRequiresTaxPayerId(gatewaytype);
    this.setState({ canSave: cs });
  };

  /**
   *
   * @param {*} paymentGateways
   * @param {import("common/brand-configuration").BrandConfiguration} brandConfiguration
   * @returns
   */
  renderNewPaymentOptions = (paymentGateways, brandConfiguration) => {
    const brandPaymentGateways = brandConfiguration.paymentSettings.categories;
    const showPgNotes = paymenttypeid =>
      PaymentGateways.find(
        entry =>
          brandPaymentGateways[entry.paymenttype] === Number(paymenttypeid)
      )?.text ?? "";
    return (
      <>
        <div className="wallet-wrap">
          <div className="modal-title" style={{ letterSpacing: "0.5" }}>
            Pay via my balance
          </div>
          {paymentGateways
            .filter(pg => pg.paymenttypeid === walletId)
            .map((pg, ind) => (
              <div
                className={classnames("wallet", {
                  "active-pg": this.state.paymenttypeid === 1,
                  "disabled-pg": pg.disabled
                })}
                key={ind}
                onClick={
                  pg.disabled
                    ? null
                    : this.choosePaymentGateway.bind(
                        this,
                        pg.paymenttypeid,
                        pg.gatewaytype
                      )
                }
              >
                My Balance:{" "}
                {formatAmountWithCurrency({
                  cost: this.props.walletBalance,
                  currency: this.props.currencySymbol,
                  currencyDisplay: "code"
                })}
              </div>
            ))}
        </div>
        <div className="pg-methods-wrap">
          <div className="modal-title" style={{ letterSpacing: "0.5" }}>
            Payment Method
          </div>
          <div className="pg-methods-item-wrap">
            {PaymentGateways.map((gateway, ind) => (
              <div
                key={ind}
                className={classnames(gateway.name, {
                  "active-pg":
                    Number(this.state.paymenttypeid) ===
                    brandPaymentGateways[gateway.paymenttype]
                })}
                onClick={() =>
                  this.choosePaymentGateway(
                    brandPaymentGateways[gateway.paymenttype],
                    gateway.type
                  )
                }
              >
                <img src={gateway.icon} alt="" />
                <p>{gateway.name}</p>
              </div>
            ))}
          </div>
          <span className="pg-notes-wrap">
            {showPgNotes(this.state.paymenttypeid)}
          </span>
        </div>
      </>
    );
  };

  renderPaymentRadioButton = (paymentGateways = []) =>
    paymentGateways.slice(0, this.state.showPayment).map(paymentGateway => {
      const [, , logoSmall1x, logoSmall2x] = getGatewayLogoMultiple(
        paymentGateway.gatewaytype
      );
      return (
        <WPBorderedRadioButton
          key={paymentGateway.gatewayname}
          labelName={paymentGateway.gatewayname}
          labelStyles={{
            fontSize: "14px"
          }}
          variantColor={"blue"}
          fieldName={"pg-options"}
          checked={this.state.paymenttypeid === paymentGateway.paymenttypeid}
          disabled={paymentGateway.disabled}
          fieldValue={paymentGateway.paymenttypeid}
          icon={logoSmall1x}
          iconSrcSet={logoSmall2x}
          iconStyle={css`
            height: 14px;
          `}
          onChange={this.choosePaymentGateway.bind(
            this,
            paymentGateway.paymenttypeid,
            paymentGateway.gatewaytype
          )}
          appendRadioDescription={
            <>
              {paymentGateway.paymenttypeid === walletId && (
                <RadioDescription>
                  Current Balance:{" "}
                  {formatAmountWithCurrency({
                    cost: this.props.walletBalance,
                    currency: this.props.currencySymbol,
                    currencyDisplay: "code"
                  })}
                </RadioDescription>
              )}
              {this.state.paymenttypeid === paymentGateway.paymenttypeid &&
                gatewayRequiresTaxPayerId(paymentGateway.gatewaytype) && (
                  <>
                    <TaxPayerLegend>
                      {this.props.t(
                        "CAPTION_TAXPAYERID_REQUIRED",
                        "Tax Payer ID is required to use this payment method"
                      )}
                    </TaxPayerLegend>
                    <TaxPayerID
                      countryCode={this.props.userCountry}
                      setCanSaveValue={this.handleUpdateCanSave}
                      showLabel={false}
                    />
                  </>
                )}
            </>
          }
        />
      );
    });

  renderGreedyTransactions = transid => {
    const { greedyTransactionsDetails: { greedyTransactions = [] } = {} } =
      this.props;
    const greedyTransactionsDOM = greedyTransactions
      .filter(greedyTransaction => greedyTransaction.transid != transid)
      .map(greedyTransaction => (
        <React.Fragment key={greedyTransaction.transid}>
          <div className="transaction-sub-orders-container">
            <div className="row">
              <div className="col-md-12">
                <div className="transaction-sub-order-date">
                  {greedyTransaction.transactiondate}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9">
                <div className="transaction-sub-order-heading pending-invoice">
                  {greedyTransaction.description}
                </div>
              </div>
              <div className="col-md-3">
                <div className="transaction-sub-order-price text-align-right">
                  {greedyTransaction.sellingcurrencysymbol}{" "}
                  {greedyTransaction.unutilisedsellingamount}
                </div>
              </div>
            </div>
          </div>
          <div className="sub-order-divider" />
        </React.Fragment>
      ));
    return greedyTransactions.length > 0 ? (
      <div className="transaction-sub-order-wrap">
        <div className="row">
          <div className="col-md-12">
            <div className="transaction-sub-order-title">
              TO PLACE THIS ORDER, YOU MUST ALSO SETTLE THESE UNPAID INVOICES:
            </div>
          </div>
        </div>
        {greedyTransactionsDOM}
      </div>
    ) : (
      []
    );
  };

  getGreedyTransactionTotalCost = transid => {
    const {
      greedyTransactionsDetails: { greedyTransactions }
    } = this.props;
    return greedyTransactions
      .filter(greedyTransaction => greedyTransaction.transid != transid)
      .reduce(
        (sum, greedyTransaction) =>
          sum + greedyTransaction.unutilisedsellingamount,
        0
      );
  };

  handleUpdateCanSave = cs => {
    this.setState({ canSave: cs });
  };

  getParsedCost(cost, walletBalance) {
    return [cost, walletBalance].map(parseFloat);
  }

  render() {
    const {
      greedyTransactionsDetails = {},
      payOnline,
      payWithWallet,
      paymentGateways,
      walletBalance: originalBalance,
      currencySymbol
    } = this.props;

    /** @type {import("common/brand-configuration").BrandConfiguration} */
    const brandConfiguration = this.props.brandConfiguration;

    const displayMode =
      brandConfiguration?.paymentSettings.displayMode ?? "list";
    const userConstraints = Syringe.inject("userConstraints");
    return (
      <ModalHandler
        opensOn={PayButtonsActionTypes.CHOOSE_PAYMENT}
        closesOn={[
          isSuccess(PayButtonsActionTypes.PAY_WITH_WALLET),
          isSuccess(PayButtonsActionTypes.PAY_ONLINE)
        ]}
        render={(
          modalProps,
          {
            startAndEndActionTypes,
            layoutInfo,
            title,
            assignedDomain,
            transid,
            goBackToPrevState,
            actionDetails: {
              action: mainAction = "",
              cost: mainActionCost = 0,
              currencySymbol: mainActionSellingCurrencySymbol = "",
              tax: mainActionTax = 0,
              ...actionsMetaData
            } = {}
          }
        ) => {
          let [cost, walletBalance] = this.getParsedCost(
            mainActionCost,
            originalBalance
          );

          const paymentOptions =
            process.env.FEATURE_ADD_FUNDS !== "none"
              ? [
                  {
                    paymenttypeid: walletId,
                    gatewaytype: "wallet",
                    gatewayname: "Wallet Balance",
                    disabled: cost > walletBalance
                  },
                  ...paymentGateways
                ]
              : paymentGateways;

          const totalAmount =
            this.getGreedyTransactionTotalCost(transid) + parseFloat(cost);

          const descriptionForPG = greedyTransactionsDetails.summary
            ? `${mainAction} , ${greedyTransactionsDetails.summary}`
            : mainAction;

          let noOfInvoices = Array.isArray(
            actionsMetaData?.value?.params?.["invoice-ids"]
          )
            ? actionsMetaData?.value?.params?.["invoice-ids"].length
            : "1";
          let orderRequestText =
            noOfInvoices > 1 ? "Order Requests" : "Order Request";

          function onFlowAbandoned() {
            modalProps.onHide();
            storage.remove("deferred-orders");
          }

          return (
            <Modal
              show={modalProps.show}
              onHide={onFlowAbandoned}
              dialogClassName="payment-modal genmodal"
              animation={false}
              keyboard
            >
              <Modal.Body>
                <div className="modal-close" onClick={onFlowAbandoned} />
                <div className="order-summary" />
                <TitleWrapper>
                  <div
                    className={cx(
                      "modal-title",
                      css`
                        font-size: 15px;
                      `
                    )}
                  >
                    {title}
                  </div>
                  {assignedDomain && (
                    <div
                      className={css`
                        margin-top: 2px;
                      `}
                    >
                      {`For: ${assignedDomain} orders`}
                    </div>
                  )}
                </TitleWrapper>
                <div
                  className="transaction-payment-modal"
                  data-testid="pay-modal"
                >
                  <div className="transaction-summary">
                    {/* <div className="buyaddons-top-tier" data-no-border="false">
                      <div
                        className="modal-title"
                        style={{ letterSpacing: "0.5" }}
                      >
                        Make an Online Payment
                      </div>
                    </div> */}
                    <div
                      className={css`
                        padding: 20px;
                      `}
                    >
                      <div className="transaction-main-order">
                        <div className="row">
                          <div className="col-md-9">
                            <div
                              className="transaction-main-order-title general-invoice"
                              data-testid="pay-modal-main-action-summary"
                            >
                              {`You are paying for ${noOfInvoices} ${orderRequestText}`}
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="transaction-main-order-price text-align-right notranslate">
                              {formatAmountWithCurrency({
                                cost,
                                currency: mainActionSellingCurrencySymbol,
                                currencyDisplay: "code"
                              })}
                            </div>
                            <div className="transaction-main-order-tax text-align-right">
                              {mainActionTax
                                ? `(Incl. ${mainActionTax}% Tax)`
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.renderGreedyTransactions(transid)}
                    </div>
                    <div className="transaction-price-wrap">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="transaction-price-title">
                            TOTAL AMOUNT TO BE PAID
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="transaction-total-order-price text-align-right notranslate">
                            {formatAmountWithCurrency({
                              cost: totalAmount,
                              currency: mainActionSellingCurrencySymbol,
                              currencyDisplay: "code"
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!userConstraints.useLegacyPayment ? (
                    <div
                      className="payment-gateway-wrap"
                      data-view-as={displayMode}
                    >
                      {process.env.FEATURE_ENDPOINT !== "bigrock" && (
                        <div
                          className="modal-title"
                          style={{ letterSpacing: "0.5" }}
                        >
                          Choose Payment Method
                        </div>
                      )}
                      <Formik
                        initialValues={{}}
                        onSubmit={() => {}}
                        render={({ values }) => (
                          <Form>
                            {displayMode === "modern"
                              ? this.renderNewPaymentOptions(
                                  paymentOptions,
                                  brandConfiguration
                                )
                              : this.renderPaymentRadioButton(paymentOptions)}
                          </Form>
                        )}
                      />
                      {displayMode === "list" &&
                        paymentOptions.length > 4 &&
                        this.state.showPayment !== paymentOptions.length && (
                          <button
                            style={{
                              fontSize: "13px",
                              fontWeight: 600,
                              marginTop: "15px",
                              padding: "0"
                            }}
                            className="wp-btn-anti"
                            onClick={() =>
                              this.setState({
                                showPayment: paymentOptions.length
                              })
                            }
                          >
                            View more payment options{" "}
                            <i className="arrow down" />
                          </button>
                        )}
                    </div>
                  ) : (
                    <div style={{ height: "30px" }} />
                  )}
                  {process.env.FEATURE_ENDPOINT === "bigrock" &&
                    Number(this.state.paymenttypeid) !== -1 && (
                      <>
                        <div className="pg-seperator"></div>
                        <div className="auto-renewal-note">
                          <span>Auto Renewal Policy:</span> Money-back guarantee
                          applies to hosting and domain privacy.{" "}
                          <span>
                            All plans and products automatically renew unless
                            you cancel.{" "}
                          </span>
                          Introductory prices apply to the first term, and will
                          automatically renew for the same term length at
                          <a
                            className="underline-highlight"
                            href="/storefront/product_regular_rate"
                            target="_blank"
                          >
                            regular rates
                          </a>{" "}
                          for the same term duration, as reflected in your
                          account under Billing. You may cancel at any time
                          prior to the renewal date from your control panel or
                          <a
                            className="underline-highlight"
                            href="/support"
                            target="_blank"
                          >
                            contact{" "}
                          </a>
                          our Support team.
                        </div>
                      </>
                    )}
                  <div
                    className="transaction-payment-wrap"
                    data-testid="pay-modal-button"
                  >
                    <APIStatus
                      key={this.state.paymenttypeid}
                      startsOn={
                        this.state.paymenttypeid === walletId
                          ? PayButtonsActionTypes.PAY_WITH_WALLET
                          : PayButtonsActionTypes.PAY_ONLINE
                      }
                    >
                      {apiStatus => (
                        <>
                          <UpdateButton
                            data-testid="proceed-to-pay"
                            className={cx(
                              "wp-btn-green",
                              css`
                                padding: 11px 30px !important;
                                line-height: 17px;
                              `
                            )}
                            disabled={
                              this.state.paymenttypeid === 0 ||
                              !this.state.canSave
                            }
                            updating={apiStatus.isFetching}
                            onClick={() => {
                              // this is temporary fix/change for netbanking payment issue
                              // TODO: the below check to be removed once netbanking payment issue is resolve
                              let selectedPGId = Number(
                                this.state.paymenttypeid
                              );
                              if (process.env.FEATURE_ENDPOINT === "bigrock") {
                                let isARDisabled =
                                  brandConfiguration.paymentSettings.autoRenewDisallowedFor
                                    .map(category =>
                                      BrandConfigurationOperations.mapCategoryToId(
                                        brandConfiguration,
                                        category
                                      )
                                    )
                                    .includes(selectedPGId);
                                if (isARDisabled) {
                                  []
                                    .concat(actionsMetaData.value)
                                    .forEach(action => {
                                      if ("auto-renew" in action.params) {
                                        action.params["auto-renew"] = false;
                                      }
                                    });
                                }
                              }
                              if (selectedPGId === walletId) {
                                payWithWallet(
                                  { cost, currencySymbol, ...actionsMetaData },
                                  startAndEndActionTypes
                                );
                              } else {
                                payOnline(
                                  {
                                    totalAmount: roundOff(
                                      parseFloat(totalAmount)
                                    ),
                                    paymenttypeid: this.state.paymenttypeid,
                                    description: descriptionForPG
                                  },
                                  actionsMetaData,
                                  layoutInfo
                                );
                              }
                              if (noOfInvoices > 1) {
                                datalayerPush("FeatureUsage", {
                                  key: "bulk_payment_start",
                                  count: noOfInvoices
                                });
                              }
                            }}
                          >
                            PAY NOW
                          </UpdateButton>
                          {goBackToPrevState && (
                            <BackButton
                              className="wp-btn-anti"
                              onClick={() => {
                                modalProps.onHide();
                                goBackToPrevState();
                              }}
                            >
                              Back
                            </BackButton>
                          )}
                        </>
                      )}
                    </APIStatus>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          );
        }}
      />
    );
  }
}

export default connect(
  PaymentModal.mapStateToProps,
  PaymentModal.mapDispatchToProps
)(withTranslation()(PaymentModal));
