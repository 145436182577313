import { GAActionTypes } from "analytics/constants";
import {
  BackOrder,
  BlockStorage,
  BusinessMail,
  CloudHosting,
  CodeGuard,
  DedicatedServer,
  DedicatedServerByBluehost,
  DomainName,
  EnterpriseEmail,
  GSuite,
  Impressly,
  MultiDomainHosting,
  ProCloud,
  ResellerHosting,
  SSL,
  SharedHosting,
  Sitelock,
  TitanMail,
  VPS,
  WebServices,
  WebsiteBuilder,
  WordpressHosting
} from "assets";
import { findNeighborInRange } from "common/math";
import { colors } from "common/styles";
import { getSimpleNameFromProductKey, monthIndices } from "constants/index";
import {
  addDays,
  addMinutes,
  differenceInCalendarDays,
  differenceInDays,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  distanceInWordsStrict,
  format,
  getDate as getDayOfTheMonth,
  getDaysInMonth,
  getMonth,
  getYear,
  isPast,
  parse
} from "date-fns";
import { flow, get, inRange, isEmpty, last as lastElem, negate } from "lodash";
import React from "react";
import Loader from "react-loaders";
import { Backup } from "../assets";
import history from "../common/history";
import { roundOff } from "./math";

export * from "./localize-amount";
export * from "./parse-string";
export * from "./urls";

export function getIcon(productKey = "") {
  switch (getSimpleNameFromProductKey(productKey)) {
    case "domain":
      return DomainName;
    case "gsuite":
      return GSuite;
    case "sdh":
    case "hgshosting":
      return SharedHosting;
    case "mdh":
      return MultiDomainHosting;
    case "vpshosting":
    case "bhvpshosting":
    case "hgdedicatedserver":
      return VPS;
    case "blockstorage":
      return BlockStorage;
    case "backup":
      return Backup;
    case "hosting":
      return WebServices;
    case "wph":
      return WordpressHosting;
    case "dedicatedserver":
    case "managedserver":
      return DedicatedServer;
    case "dedibybh":
      return DedicatedServerByBluehost;
    case "cloudhosting":
    case "businesscloud":
      return CloudHosting;
    case "wpcloud":
    case "procloud":
      return ProCloud;
    case "businessemail":
      return BusinessMail;
    case "enterpriseemail":
      return EnterpriseEmail;
    case "codeguard":
      return CodeGuard;
    case "sslcert":
      return SSL;
    case "rchosting":
      return ResellerHosting;
    case "impressly":
      return Impressly;
    case "sitelock":
      return Sitelock;
    case "webzai":
    case "websitebuilder":
      return WebsiteBuilder;
    case "backorder":
      return BackOrder;
    case "titanmail":
      return TitanMail;
    default:
  }
}

export const getDate = (creationTimestamp, expiryTimestamp) =>
  `${convertUTCTimestampToDate(
    creationTimestamp
  )} - ${convertUTCTimestampToDate(expiryTimestamp)}`;

export const roundOffMoney = (value, noOfDigitsAfterDecimal = 2) =>
  isValidNumber(value) ? value.toFixed(noOfDigitsAfterDecimal) : "";

export const convertUNIXToDate = (unixTimestamp = "") =>
  parse(parseInt(unixTimestamp, 10) * 1000);

export const convertUTCTimestampToDate = (
  timestamp = "",
  dateFormat = "Do MMM, YYYY"
) =>
  isEmpty(timestamp)
    ? ""
    : format(normalizeOffsetFromDate(convertUNIXToDate(timestamp)), dateFormat);

export const addDaysToUNIXTimestamp = (timestamp = 0, days = 0) =>
  addDays(new Date(+timestamp * 1000), days);

export const formatDate = date => (date ? format(date, "Do MMM, YYYY") : null);
export const formatDateTime = date =>
  date ? format(date, "Do MMM, YYYY - h:mm:ss a") : null;
export const convertUTCTimestampToDateTime = (timestamp = "") =>
  convertUTCTimestampToDate(timestamp, "Do MMM, YYYY - h:mm:ss a");

export function getAmountAlongWithTax(cost = 0, tax = 0) {
  cost = parseFloat(cost);
  tax = parseFloat(tax);
  return roundOffMoney(cost * (1 + tax / 100));
}

export const getTaxAmount = (cost = 0, tax = 0) =>
  roundOffMoney((parseFloat(cost) * parseFloat(tax)) / 100);

export const getInitials = (username = "", limit = 2) =>
  username
    .split(/\s+/)
    .map(_ => _.charAt(0).toUpperCase())
    .filter(_ => /[^()-]/.test(_))
    .join("")
    .substr(0, limit);

export function getDuration(startUNIXTimestamp, expiryUNIXTimestamp) {
  let startDate = convertUNIXToDate(startUNIXTimestamp);
  let expiryDate = convertUNIXToDate(expiryUNIXTimestamp);
  let monthDiff = differenceInMonths(expiryDate, startDate);
  let yearDiff = Math.floor(monthDiff / 12);
  monthDiff = monthDiff % 12;
  const yearText =
    yearDiff > 0 ? yearDiff + (yearDiff > 1 ? " Years " : " Year ") : "";

  const monthText =
    monthDiff > 0 ? monthDiff + (monthDiff > 1 ? " Months " : " Month ") : "";

  return `${yearText} ${monthText}`;
}

export function getFormattedDateDifference(
  endTimestamp,
  startTimestamp,
  options = { separator: " " }
) {
  const { separator } = options;
  const converters = { days: 24 * 60 * 60, hours: 60 * 60, minutes: 60 };
  let diffInSeconds = differenceInSeconds(endTimestamp, startTimestamp);
  const convertedValues = {};
  Object.entries(converters).forEach(([converterKey, converter]) => {
    let nextValue = Math.floor(diffInSeconds / converter);
    convertedValues[converterKey] = nextValue;
    diffInSeconds = diffInSeconds % converter;
  });
  return Object.entries(convertedValues).reduce(
    (formattedDate, [key, value]) =>
      `${formattedDate} ${value} ${key}${separator}`,
    ""
  );
}

export const getFormattedDate = convertUTCTimestampToDate;

export const getDaysRemaining = (expiryDate = "") =>
  distanceInWordsStrict(new Date(), convertUNIXToDate(expiryDate), {
    addSuffix: true,
    unit: "d"
  });

export const calculateDaysInCurrentMonth = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const hasOrderExpired = flow([convertUNIXToDate, isPast]);

// `min` is inclusive. `max` is exclusive.
export const isValueInRange = (min, max) => value => inRange(value, min, max);

export const differenceInDaysFromNow = date =>
  differenceInDays(convertUNIXToDate(date), new Date());

export const isOrderExpiringIn30Days = flow([
  differenceInDaysFromNow,
  isValueInRange(0, 31)
]);

export const isOrderExpiringIn15Days = flow([
  differenceInDaysFromNow,
  isValueInRange(0, 15)
]);

export const getParsedAndAbsoluteValue = flow([parseFloat, Math.abs]);

export function getRemainingDaysFromNow(enddatetime) {
  if (enddatetime === undefined) {
    return "";
  }
  const enddate = convertUNIXToDate(enddatetime);
  //to account for current day
  const numberOfDays = differenceInCalendarDays(enddate, new Date());
  return numberOfDays > 0 ? numberOfDays : 0;
}

export function remainingMinutesFromNow(startdate) {
  let startdateDate = convertUNIXToDate(startdate);
  return differenceInMinutes(new Date(), startdateDate);
}

export function getProrataCost(costPerMonth = -1, enddatetime) {
  if (costPerMonth < 0 || enddatetime === undefined) {
    return 0;
  }
  return costPerMonth * getDifferenceInMonths(enddatetime);
}

export function getPrivacyProtectionCost(cost, remainingDays) {
  /* Privacy protection cost on Obox is calculated as follows,
  for more info refer DomOrder.java and method calculateNetPrivacyCost */
  return +((parseFloat(cost) * remainingDays) / 365);
}

export function getPrivacyProtectionTenure(
  endtime,
  ppEndtime = Date.now() / 1000
) {
  const diff = parseInt(endtime, 10) - parseInt(ppEndtime, 10);
  return roundingInt(diff / (3600 * 24));
}

function roundingInt(value) {
  /* Even number rounding off */
  let wholePart = parseInt(value);
  return (value - wholePart) * 100 === 50
    ? wholePart + (wholePart % 2)
    : Math.round(value);
}

export function printWindow(url) {
  let printWindow = window.open(url);
  printWindow.addEventListener("load", _ => printWindow.print());
}

export const getOrderManagementPage = (domain, product = "all") =>
  !isEmpty(domain) ? `/orders/manage/${domain}/${product}` : "";

export const openPage = pathname => {
  if (pathname) {
    history.push(pathname);
  }
};

export const openOrderManagementPage = flow([getOrderManagementPage, openPage]);

export function getProduct(productCategory, productKey) {
  productKey = productCategory === "domorder" ? productCategory : productKey;
  return getSimpleNameFromProductKey(productKey);
}

export const isValidNumber = negate(isNaN);

export const showLoader = (
  <div className="loader-fetching">
    <div>
      <Loader type="ball-pulse" active />
    </div>
  </div>
);

export const getRangeForValue = (valuesByRange = {}, valueToFind) => {
  valueToFind = parseFloat(valueToFind);
  return Object.entries(valuesByRange).find(([range, values]) => {
    const [min, max] = range.match(/\d+/g).map(parseFloat);
    return valueToFind >= min && valueToFind <= max ? values : null;
  });
};

export const getValueInRange = flow([getRangeForValue, lastElem]);

export const getTaxInfo = ({ productcategory = "" }, taxinfo) =>
  (taxinfo?.taas?.[productcategory] ?? 0) +
  (taxinfo?.vat?.[productcategory] ?? 0) +
  (taxinfo?.["regional-and-custom-taxes"]?.[productcategory] ?? 0) +
  (taxinfo?.[productcategory] ?? 0);

export const formatBytes = (numberOfBytes = 0, unit = "MB") => {
  const UNITS = ["KB", "MB", "GB", "TB"];
  let currentUnit = UNITS.indexOf(unit.toUpperCase());
  let convertedBytes = numberOfBytes;
  while (convertedBytes >= 1024) {
    convertedBytes /= 1024;
    currentUnit++;
  }
  if (currentUnit === UNITS.indexOf("GB") && convertedBytes === 1000) {
    convertedBytes /= 1000;
    currentUnit++;
  }
  return `${convertedBytes} ${UNITS[currentUnit]}`;
};

export const reduceObject = (map, partial) => ({ ...map, ...partial });

export const createErrorMap =
  (errorSheet = {}) =>
  (valueMap = {}) =>
    Object.entries(errorSheet)
      .map(([errorName, opts]) => {
        // FieldArray handling
        if (opts.arrayKey) {
          let { arrayKey, ...errorSheet } = opts;
          const errorList = valueMap[arrayKey].map(createErrorMap(errorSheet));
          return errorList.every(isEmpty)
            ? {}
            : {
                [arrayKey]: errorList
              };
        }
        let hasMultipleChecks = Array.isArray(opts);
        let errorMessages = []
          .concat(opts)
          .filter(opt => opt.isWrong(valueMap))
          .map(opt => opt.message(valueMap));

        return errorMessages.length > 0
          ? {
              [errorName]: hasMultipleChecks
                ? errorMessages
                : lastElem(errorMessages)
            }
          : {};
      })
      .reduce(reduceObject, {});

export const applyAll =
  (...fns) =>
  (...args) =>
    fns.forEach(fn => fn(...args));

/**
 *
 * @param {<A, B>(f1: (state: A, action: import("redux").Action) => A, f2: (state: B, action: import("redux").Action) => B)} reducers
 * @returns {<A,B>(a2: A & B, action: import("redux").Action) => A & B}
 */
export const composeReducers =
  (...reducers) =>
  (state, action) =>
    reducers.reduceRight((state, reducer) => reducer(state, action), state);

export const normalizeOffsetFromDate = date => {
  let diffInMinutes = date.getTimezoneOffset();
  return addMinutes(date, diffInMinutes);
};
export const getDifferenceInMonths = (
  endDateTimestamp,
  startDate = new Date()
) => {
  if (!endDateTimestamp) {
    return 0;
  }
  const endDate = convertUNIXToDate(endDateTimestamp);
  let normalizedDate = normalizeOffsetFromDate(endDate);
  let yearDifference = getYear(normalizedDate) - getYear(startDate);
  let monthDifference = getMonth(normalizedDate) - getMonth(startDate);
  if (yearDifference >= 1) {
    yearDifference--;
    monthDifference += 12;
  }
  const startDay = getDayOfTheMonth(startDate);
  const endDay = getDayOfTheMonth(normalizedDate);

  const totalDaysInStartMonth = getDaysInMonth(startDate);

  const diffInDays = endDay - startDay;

  let fractionOfMonth = 0;
  if (diffInDays < 0) {
    // implies that date in start month is greater than that of end month
    // so we calculate the fraction of days in start and end months
    monthDifference--;
    const daysInStartMonth = totalDaysInStartMonth - startDay;
    const daysInEndMonth = endDay;
    fractionOfMonth = getFractionalMonth(
      daysInStartMonth + daysInEndMonth,
      normalizedDate
    );
    if (fractionOfMonth > 1) {
      fractionOfMonth = 1;
    }
  } else {
    fractionOfMonth = getFractionalMonth(diffInDays, normalizedDate);
  }
  return roundOff(
    parseFloat(yearDifference) * 12 +
      parseFloat(monthDifference) +
      parseFloat(fractionOfMonth)
  );
};

export const roundOffDate = date => {
  return Math.round(date / 1000);
};

export const getFractionalMonth = (days, endDate) =>
  days / getDaysInMonth(endDate); // fractional part of the month is calculated as a fraction of the number of days in the expiry month

export const emitGAEvent = trackingInfo => ({
  type: GAActionTypes.emitActionType,
  ...trackingInfo
});

/*
We cannot use the parse function from date-fns
since internally it passes it to new Date() of the browser
and firefox does not parse hyphen separated date
https://github.com/date-fns/date-fns/pull/690
*/
export const getTimestampFromHyphenSeparatedDate = date => {
  const [day, month, year] = date.split("-");
  return new Date(`${monthIndices[month]}/${day}/${year}`).getTime() / 1000;
};

export const isFocusWorkaroundNeeded = process.env.SSR
  ? false
  : window.navigator
  ? window.navigator.platform.toLowerCase().startsWith("mac") &&
    !window.navigator.vendor.toLowerCase().startsWith("google")
  : false;

export function trapEvent(handler) {
  return function eventHandler(event) {
    event.stopPropagation();
    event.preventDefault();
    handler(event);
  };
}

export const OBOX_ORDER_STATUS = {
  ACTIVE: "active",
  SUSPENDED: "suspended",
  EXPIRED: "expired",
  INACTIVE: "inactive",
  DUMMY: "dummy"
};

export const getBorderAccentColor = orderStatus => {
  switch (orderStatus) {
    case OBOX_ORDER_STATUS.ACTIVE:
      return colors.green.regular;
    case OBOX_ORDER_STATUS.SUSPENDED:
    case OBOX_ORDER_STATUS.EXPIRED:
      return colors.red.regular;
    case OBOX_ORDER_STATUS.INACTIVE:
    case OBOX_ORDER_STATUS.DUMMY:
      return colors.gray.grayInactive;
    default:
      return colors.white.regular;
  }
};

/**
 * @typedef OnholdOrder
 * @property {string} orderId
 */

/**
 *
 * @param {string | number} orderid
 * @param {OnholdOrder[]} onholdOrders
 * @returns {OnholdOrder | null}
 */
export const getOnHoldOrder = (orderid, onholdOrders) =>
  (orderid &&
    onholdOrders &&
    onholdOrders.find(action => action.orderId == orderid)) ||
  null;

export const getAllOnHoldOrders = (orderids = [], onholdOrders) => {
  return orderids
    .map(id =>
      onholdOrders.find(action =>
        action.orderid == id || action.orderId == id ? action : null
      )
    )
    .filter(Boolean);
};

export const excludeTrialOrders = (orders = [], ordersProps = {}) => {
  const trialExcludedOrders = orders.filter(_ => !_.isTrial);
  return {
    orders: trialExcludedOrders,
    orderProps: {
      ...ordersProps,
      maxCount: trialExcludedOrders.length
    }
  };
};

export const round = value => value.toFixed(value % 1 && 2);

export function allowsChangePlan(productKey, planDetails, orderBoxData) {
  if (orderBoxData?.currentstatus !== "Active") {
    return false;
  }
  const simpleProductName = getSimpleNameFromProductKey(productKey);
  if (simpleProductName === "vpshosting") {
    return planDetails?.isSSDPlan;
  } else if (simpleProductName === "gsuite") {
    return orderBoxData.planid !== 1554;
  } else {
    return [
      "codeguard",
      "gsuite",
      "hgshosting",
      "mdh",
      "rchosting",
      "sdh",
      "sitelock"
    ].includes(simpleProductName);
  }
}

export const getDurationsByPrices = prices =>
  Object.keys(prices)
    .map(Number)
    .sort((a, b) => a - b);

export const parseCustomerPricing = (
  pricingDetails,
  customerId,
  { productKey, expiryTimestamp },
  id
) => {
  let defaultPricingPath = [customerId, productKey, id];
  let pricingPath;
  switch (getSimpleNameFromProductKey(productKey)) {
    // since different products require different parsing logic
    case "enterpriseemail":
    case "wph":
    case "dedibybh":
    case "managedserver":
    case "hgdedicatedserver":
    case "dedicatedserver":
    case "bhvpshosting":
    case "vpshosting": {
      pricingPath = [customerId, productKey, "addons"];
      break;
    }
    case "gsuite":
    case "backup":
    case "wpcloud":
    case "titanmail":
    case "blockstorage": {
      pricingPath = [customerId, productKey, "plans", id];
      break;
    }
    case "sdh": {
      pricingPath =
        process.env.FEATURE_ENDPOINT !== "reseller"
          ? [customerId, productKey, productKey, id, "pricing"]
          : defaultPricingPath;
      break;
    }
    case "businessemail":
    case "domain": {
      pricingPath = [customerId, productKey];
      break;
    }
    default:
      pricingPath = defaultPricingPath;
      break;
  }
  let pricing = get(pricingDetails, pricingPath, {
    error: "Could not get prices"
  });
  // Object.keys(pricing).forEach(e => { pricing[e] = getProrataCost(pricing[e], expiryTimestamp) })
  return pricing.ssl ||
    pricing.ipaddress ||
    pricing.managed_services ||
    pricing.whmcs
    ? {
        ...pricing,
        ssl: getProrataCost(pricing.ssl || pricing.ipaddress, expiryTimestamp),
        ...(pricing.managed_services && {
          managed_services: getProrataCost(
            pricing.managed_services,
            expiryTimestamp
          )
        }),
        ...(pricing.whmcs && {
          whmcs: getProrataCost(pricing.whmcs, expiryTimestamp)
        })
      }
    : pricing;
};

/**
 *
 * @param {import("../constants/index").SimpleName} simpleName
 */
export function isIntegratedWithExpose(simpleName) {
  return ["backorder", "wph", "titanmail", "wpcloud"].includes(simpleName);
}

export const createCookie = (name, value, days) => {
  let date = "";
  let expires = "";
  if (days) {
    date = new Date();
    date.setDate(date.getDate() + days);
    expires = "; expires=" + date.toUTCString();
  } else {
    expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};

export const getPrivacyProtectionAddonPrice = ({
  addonDetails,
  pricingDetails,
  customerPricingDetails,
  expirytimestamp,
  selectedTenure
}) => {
  const hasAddonBeenPurchased = addonDetails?.hasAddonBeenPurchased;
  // since privacy protection price is for 12 months
  const proratedCost = +getPrivacyProtectionCost(
    pricingDetails,
    getPrivacyProtectionTenure(expirytimestamp)
  );
  const customerProratedCost = +getPrivacyProtectionCost(
    customerPricingDetails,
    getPrivacyProtectionTenure(expirytimestamp)
  );
  return hasAddonBeenPurchased
    ? {
        addonPrice: parseFloat(pricingDetails) * +selectedTenure,
        customerAddonPrice: parseFloat(customerPricingDetails) * +selectedTenure
      }
    : {
        addonPrice: proratedCost + parseFloat(pricingDetails) * +selectedTenure,
        customerAddonPrice:
          customerProratedCost +
          parseFloat(customerPricingDetails) * +selectedTenure
      };
};

export function isFetchDetailRequiredForOrder(order) {
  return ["titanmail"].includes(getSimpleNameFromProductKey(order.productKey));
}

export function calculateTenureFloor(pricing = {}, givenTenure) {
  let tenures = Object.keys(pricing)
    .map(Number)
    .sort((a, b) => a - b);
  return findNeighborInRange(tenures, givenTenure);
}

export function hasMore(paginationData) {
  return {
    hasMore:
      paginationData?.maxCount > paginationData?.orderIDs.filter(Number).length
  };
}
