import { FLOW_LEVEL } from "./constants";
import { isFailure, isSuccess } from "common/api-status/utils";

export const actionTypes = {
  UPDATE_FLOW: "[Customer Login] Update flow",
  UPDATE_CONTEXT: "[Customer Login] Update context",

  PASSWORD_INSTRUCTIONS_REQUEST: "[Customer Login] Reset Instructions",

  CHANGE_PASSWORD_REQUEST: "[Customer Login] Reset Password",

  VALIDATE_PASSWORD_HASH_REQUEST: "[Customer Login] Validate Password Hash"
};

const initialState = {
  flowLevel: FLOW_LEVEL.ENTER_LOGIN,
  flowContext: {}
};

export const actions = {
  moveToLoginPage: () => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.ENTER_LOGIN,
    flowContext: {}
  }),
  moveToForgotPassword: () => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.FORGOT_PASSWORD,
    flowContext: {
      email: "",
      isFormSubmitted: false
    }
  }),
  moveTo2FA: flowContext => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.LOGIN_TWO_FACTOR,
    flowContext
  }),
  moveToResetPassword: flowContext => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.RESET_PASSWORD,
    flowContext
  }),
  moveToSetPassword: flowContext => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.SET_PASSWORD,
    flowContext
  }),
  moveToPanel: flowContext => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.EXIT_LOGIN,
    flowContext
  }),
  moveToInvalidHash: () => ({
    type: actionTypes.UPDATE_FLOW,
    flowLevel: FLOW_LEVEL.INVALID_HASH
  }),
  updateFlowContext: context => ({
    type: actionTypes.UPDATE_CONTEXT,
    context
  }),
  sendPasswordInstructions: payload => ({
    type: actionTypes.PASSWORD_INSTRUCTIONS_REQUEST,
    payload
  }),
  sendPasswordInstructionsSuccess: payload => ({
    type: isSuccess(actionTypes.PASSWORD_INSTRUCTIONS_REQUEST),
    payload
  }),
  sendPasswordInstructionsFailure: error => ({
    type: isFailure(actionTypes.PASSWORD_INSTRUCTIONS_REQUEST),
    error
  }),
  changePassword: payload => ({
    type: actionTypes.CHANGE_PASSWORD_REQUEST,
    payload
  }),
  changePasswordSuccess: payload => ({
    type: isSuccess(actionTypes.CHANGE_PASSWORD_REQUEST),
    payload
  }),
  changePasswordFailure: error => ({
    type: isFailure(actionTypes.CHANGE_PASSWORD_REQUEST),
    error
  }),
  validatePasswordHash: payload => ({
    type: actionTypes.VALIDATE_PASSWORD_HASH_REQUEST,
    payload
  }),
  validatePasswordHashSuccess: payload => ({
    type: isSuccess(actionTypes.VALIDATE_PASSWORD_HASH_REQUEST),
    payload
  }),
  validatePasswordHashFailure: error => ({
    type: isFailure(actionTypes.VALIDATE_PASSWORD_HASH_REQUEST),
    error
  })
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTEXT:
      return {
        ...state,
        flowContext: {
          ...state.flowContext,
          ...action.context
        }
      };

    case actionTypes.UPDATE_FLOW:
      return {
        ...state,
        flowLevel: action.flowLevel,
        flowContext: action.flowContext
      };

    default:
      return state;
  }
};
