import storage from "@faizaanceg/pandora";
import { Syringe } from "@faizaanceg/syringe";
import { BrandConfigurationOperations } from "common/brand-configuration";
import {
  actions as inlineNotificationActions,
  constants as inlineNotificationConstants
} from "common/inline-notifications/ducks";
import { TransactionsSdk } from "common/sdk/transactions";
import { addQueryParamsToUrl } from "common/urls";
import { mapEffect } from "map-effect";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { actionTypes } from "./ducks";
import { services } from "./services";

export const payButtonsSagas = [
  takeEvery(
    actionTypes.GET_CURRENCY_CONVERSTION_INFO,
    mapEffect(getConversionRate)
  ),
  takeEvery(
    actionTypes.GET_GREEDY_TRANSACTIONS,
    mapEffect(() => TransactionsSdk.greedy())
  ),
  takeEvery(actionTypes.PAY_ONLINE, mapEffect(payOnline)),
  takeEvery(actionTypes.PAY_WITH_WALLET, mapEffect(payWithWallet))
];

function getConversionRate({ payload }) {
  return payload.currencySymbol === "USD"
    ? 1.0
    : services.getCurrencyConversionRate(payload.currencySymbol, "USD");
}

/**
 * Creates a payment link to complete Payments
 */
async function createPaymentLink(payload) {
  try {
    const response = await services.createPaymentLink(payload);
    window.location.href = response.redirectUrl;
  } catch (error) {
    console.error(error);
  }
}

function* payOnline(action) {
  let role = yield select(state => state.root.userInfo.role);
  if (role === "reseller") {
    const response = yield services.createResellerPaymentLink(action.payload);
    storeMetadata(action);
    window.location.href = response.url;
    return;
  }
  let actionsToPerform = action.payload.actionsMetaData.value;
  if (!Array.isArray(actionsToPerform)) {
    actionsToPerform = [actionsToPerform];
  }
  let { allErrors, allInvoiceIds } = yield onGetInvoiceIds(actionsToPerform);
  if (allErrors.length > 0) {
    yield payWithWalletFailure(allErrors);
    return;
  }
  /** @type {import("common/brand-configuration").BrandConfiguration} */
  let brandConfiguration = yield select(state =>
    BrandConfigurationOperations.findById(state.root.userInfo.parentid)
  );
  let usesOfflinePayment =
    brandConfiguration?.paymentSettings?.categories?.cheque ===
    Number(action.payload.paymenttypeid);
  if (usesOfflinePayment) {
    window.open(brandConfiguration.urls.storefront.offlinePaymentOptions);
  } else {
    const { paymenttypeid } = action.payload;
    if (Number(paymenttypeid) === -2) {
      window.location.href = addQueryParamsToUrl(`/servlet/PaymentServlet`, {
        payfor: role,
        transid_type: allInvoiceIds.map(invoice => `${invoice}_invoice`)
      });
      return;
    }
    /** @type {import("common/orderbox-types").PaymentGateway[]} */
    const paymentGateways = yield select(state => state.wallet.paymentGateways);
    const selectedGateway = paymentGateways.find(
      gateway => Number(gateway.paymenttypeid) === Number(paymenttypeid)
    );
    if (selectedGateway?.gatewaytype === "custom") {
      yield services.payToCustomGateway({
        invoiceIds: allInvoiceIds,
        ...action.payload
      });
    } else {
      yield createPaymentLink({ invoiceIds: allInvoiceIds, ...action.payload });
    }
  }
}

function storeMetadata(action) {
  let { actionsMetaData = {}, key, layoutInfo } = action.payload;
  storage.set("retryPaymentAction", {
    ...storage.get("retryPaymentAction", {}),
    [key]: {
      payload: action.payload,
      actionsMetaData: { ...actionsMetaData, uniqueKey: key }
    }
  });
  storage.set("layoutInfo", {
    ...storage.get("layoutInfo", {}),
    [key]: layoutInfo
  });
  storage.set("paymentGatewaySuccessActions", {
    ...storage.get("paymentGatewaySuccessActions", {}),
    [key]: actionsMetaData.value
  });
}

function* onGetInvoiceIds(actionToBePerformedValue) {
  let allResponses = [];
  let allErrors = [];
  if (process.env.FEATURE_ENDPOINT === "customer") {
    let pendingInvoices = yield all(
      actionToBePerformedValue
        .filter(action => action.params["invoice-ids"] === undefined)
        .map(order => call(TransactionsSdk.orders.pending, order.meta.orderid))
    );
    let doesApiUseCustomerRole = Syringe.inject("doesApiUseCustomerRole");
    let invoiceIds = Object.values(pendingInvoices)
      .map(invoice =>
        Object.entries(
          doesApiUseCustomerRole
            ? invoice.pendingInvoices
            : invoice.pendingChildInvoices
        )
          .map(([key, order]) => (!isNaN(key) ? order.transid : ""))
          .filter(Boolean)
      )
      .flat();
    if (invoiceIds.length > 0) {
      yield TransactionsSdk.customer.cancelPendingInvoices(invoiceIds);
    }
  }
  for (let payload of actionToBePerformedValue) {
    if (payload.params?.["invoice-ids"]) {
      allResponses.push({
        payload,
        response: { invoiceid: payload.params["invoice-ids"] }
      });
    } else {
      try {
        let response = yield services.placeAction(payload);
        allResponses.push({ payload, response });
      } catch (error) {
        allErrors.push({ payload, error });
      }
    }
  }
  let allInvoiceIds = allResponses.map(action => {
    const invoices = [];
    invoices.push(
      action.response.invoiceid ??
        action.response[action.payload.meta?.domainname]?.invoiceid
    );
    if (action.response?.privacydetails?.invoiceid) {
      invoices.push(action.response.privacydetails.invoiceid);
    }
    if (action.response?.premiumdnsdetails?.invoiceid) {
      invoices.push(action.response.premiumdnsdetails.invoiceid);
    }
    return invoices;
  });
  allInvoiceIds = allInvoiceIds.flat(2);
  return { allInvoiceIds, allResponses, allErrors };
}

function* customerPay(actionsToPerform) {
  try {
    const {
      allInvoiceIds,
      allErrors = [],
      allResponses = []
    } = yield onGetInvoiceIds(actionsToPerform);
    if (allErrors.length === 0) {
      yield services.customerPay(allInvoiceIds);
      yield payWithWalletSuccess(allResponses);
    } else {
      yield payWithWalletFailure(allErrors);
    }
    return { allResponses, allErrors };
  } catch (error) {
    console.error("payment or get invoice id failed", error.message);
  }
}

function* resellerPay(actionsToPerform) {
  let allResponses = [];
  let allErrors = [];
  for (let payload of actionsToPerform) {
    try {
      let response = yield services.placeAction(payload);
      allResponses.push({ payload, response });
    } catch (error) {
      allErrors.push({ payload, error });
    }
  }
  yield payWithWalletSuccess(allResponses);
  yield payWithWalletFailure(allErrors);
  return { allResponses, allErrors };
}

function* payWithWalletSuccess(actions = []) {
  for (let action of actions) {
    const {
      response: value,
      payload: {
        actionTypes: {
          successActionType: type = "",
          successActionPayload: payload = {}
        } = {}
      } = {}
    } = action;
    let defaultMessage = payload.defaultMessage;
    yield put({ type, value, payload, defaultMessage });
  }
}

function* payWithWalletFailure(actions = []) {
  for (let action of actions) {
    const {
      error,
      payload: {
        actionTypes: {
          failureActionType: type = "",
          failureActionPayload: payload = {}
        } = {}
      } = {}
    } = action;
    let defaultMessage =
      payload.defaultMessage ?? `Payment failed : ${error.message}`;
    yield put({ type, error, payload, defaultMessage });
  }
}

function* payWithWallet(action) {
  let {
    actionDetails: { value: actionsToPerform },
    startAndEndActionTypes: { startActionType, startActionPayload = {} }
  } = action;
  if (startActionType) {
    yield put({ type: startActionType, ...startActionPayload });
  }
  if (!Array.isArray(actionsToPerform)) {
    actionsToPerform = [actionsToPerform];
  }
  let role = yield select(state => state.root.userInfo.role);
  const placeActions = role === "customer" ? customerPay : resellerPay;
  return yield placeActions(actionsToPerform);
}

payWithWallet.onSuccess = function* (action, paymentSummary) {
  let {
    startAndEndActionTypes: {
      endActionType: type,
      endActionPayload: payload = {}
    }
  } = action;
  if (type) {
    yield put({ type, ...payload, paymentSummary });
  }
  if (paymentSummary.allErrors.length === 0) {
    yield put(
      inlineNotificationActions.showNotification(
        "We have successfully received your payment",
        inlineNotificationConstants.SUCCESS
      )
    );
  } else {
    const error =
      paymentSummary.allErrors[0]?.error.message ||
      "Something went wrong! please try again";
    yield put(
      inlineNotificationActions.showNotification(
        error,
        inlineNotificationConstants.ERROR
      )
    );
  }
};
